import { Button, Grid, Typography } from '@mui/material';

export interface RoundedButtonProps {
	label: string;
	color: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
	disabled: boolean;
	icon?: JSX.Element;
	handleOnClickEvent: (event?: React.SyntheticEvent | Event, reason?: string) => void;
}

export default function RoundedButton(props: RoundedButtonProps) {
	return (
		<Button
			color={props.color}
			variant="contained"
			disabled={props.disabled}
			sx={{
				borderRadius: '5px',
				height: '30px',
				padding: '0 16px'
			}}
			fullWidth
			onClick={props.handleOnClickEvent}
		>
			<Grid container spacing={1} alignItems="center" justifyContent="center">
				{!props.icon ? ( 
					<Grid item sx={{ textAlign: 'center' }}>
						<Typography variant="body1">{props.label}</Typography>
					</Grid>
				) : (
					<>
						<Grid item sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
							{props.icon}
						</Grid>
						<Grid item sx={{ flexGrow: 3, textAlign: 'left' }}>
							<Typography variant="body1">{props.label}</Typography>
						</Grid>
					</>
				)}
			</Grid>
		</Button>
	);
}
