import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import {
	Accordion,
	AccordionActions,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Divider,
	Grid2 as Grid,
	InputAdornment,
	MenuItem,
	SelectChangeEvent,
	Stack,
	TextField,
	Typography
} from '@mui/material';
import { Currency } from 'aws-sdk/clients/lightsail';
import { useEffect, useMemo, useState } from 'react';
import { FieldArrayWithId } from 'react-hook-form/';
import { axiosConfig } from '../../../../constants/axios';
import { useMerchant } from '../../../../context/MerchantContext';
import { CurrencyExchangeRateRequest, CurrencyExchangeRateResponse } from '../../../../types/currency';
import { ProductRecord } from '../../../../types/product';
import { currencies } from '../../../../util/currency';
import theme from '../../../../util/theme';
import { ShipmentActionTypes } from '../../context/actions';
import { useShipmentContext } from '../../hooks/useShipmentContext';
import { CustomsItemData, ParcelData } from '../../types/consignment';
import CustomsItems from './CustomsItems';

// TODO fix these types, using any for now
interface Props {
	control: any;
	register: any;
	getValues: any;
	setValue: any;
	errors: any;
	watch: any;
	remove: any;
	trigger: any;
	fields: FieldArrayWithId[];
}

export const currencyExchangeRateDefault: CurrencyExchangeRateResponse = {
	source_currency: 'ZAR',
	target_currency: 'ZAR',
	rate: 1,
	converted_amount: 0
};

export default function ParcelItem({
	control,
	register,
	getValues,
	setValue,
	errors,
	fields,
	remove,
	watch,
	trigger
}: Props) {
	const axiosInstance = axiosConfig();
	const { merchantProfile } = useMerchant();
	const [products, setProducts] = useState<ProductRecord[]>();
	const [currency, setCurrency] = useState<string>('');
	const shipmentContext = useShipmentContext();
	const { dispatch } = shipmentContext;

	useEffect(() => {
		axiosInstance
			.post('/product/getProductsByMerchantId', {
				merchant_id: merchantProfile.partitionKey
			})
			.then(response => {
				setProducts(response.data);
			})
			.catch(err => {
				console.log(err);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [merchantProfile]);

	const calculateVolumetric = (length: number, width: number, height: number) => {
		try {
			return (length * width * height) / 5000;
		} catch {
			return null;
		}
	};

	const customsItemsValues = watch('parcels', []).flatMap((parcel: ParcelData) =>
		parcel?.customsItems?.map((item: CustomsItemData) => item?.value || 0)
	);

	const totalValue = useMemo(() => {
		return customsItemsValues.reduce((total: number, value: number) => total + value, 0);
	}, [customsItemsValues]);

	useEffect(() => {
		dispatch({
			type: ShipmentActionTypes.UPDATE_PROTECTION_VALUE,
			payload: totalValue
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totalValue]);

	const getExchangeRate = async (sourceCurrency: Currency): Promise<CurrencyExchangeRateResponse> => {
		const requestData: CurrencyExchangeRateRequest = {
			source_currency: sourceCurrency,
			target_currency: 'ZAR',
			amount: 1
		};
		try {
			const response = await axiosInstance.post('/currency/convert', requestData);
			const exchangeRate: CurrencyExchangeRateResponse = response.data.data;
			dispatch({
				type: ShipmentActionTypes.UPDATE_EXCHANGE_RATE,
				payload: exchangeRate
			});

			return exchangeRate;
		} catch (error) {
			//TODO: inform user of error, currency to default to ZAR
			console.log(error);
			setValue('customsInfo.currency', 'ZAR');
			setCurrency('ZAR');
			dispatch({
				type: ShipmentActionTypes.UPDATE_EXCHANGE_RATE,
				payload: currencyExchangeRateDefault
			});
			return currencyExchangeRateDefault;
		}
	};
	const handleCurrencyChange = async (event: SelectChangeEvent) => {
		const value = event.target.value;
		setValue('customsInfo.currency', value);
		setCurrency(value);
		if (value === 'ZAR') {
			dispatch({
				type: ShipmentActionTypes.UPDATE_EXCHANGE_RATE,
				payload: currencyExchangeRateDefault
			});
			return;
		}

		await getExchangeRate(value);
	};

	return (
		<Grid>
			<Box sx={{ my: 1 }}>
				<Typography variant="subtitle1">Order details</Typography>
				<Grid container direction="row" spacing={1}>
					<Grid size={3}>
						<TextField
							select
							fullWidth
							label="Currency"
							size="small"
							{...register('customsInfo.currency')}
							value={getValues('customsInfo.currency')}
							error={!!errors?.customsInfo?.currency}
							helperText={errors?.customsInfo?.currency?.message}
							onBlur={() => {
								trigger('customsInfo.currency');
							}}
							onChange={handleCurrencyChange}
						>
							<MenuItem disabled value="">
								<em>Select a currency</em>
							</MenuItem>

							{currencies.map(currency => {
								return (
									<MenuItem key={currency} value={currency}>
										{currency}
									</MenuItem>
								);
							})}
						</TextField>
					</Grid>
					<Grid size={3}>
						<TextField
							select
							fullWidth
							label="Contents Type"
							size="small"
							defaultValue={'merchandise'}
							{...register('customsInfo.contentsType')}
							error={!!errors?.customsInfo?.contentsType}
							helperText={errors?.customsInfo?.contentsType?.message}
							onBlur={() => {
								trigger('customsInfo.contentsType');
							}}
						>
							<MenuItem disabled value="">
								<em>Select content type</em>
							</MenuItem>

							<MenuItem value={'merchandise'}>Merchandise</MenuItem>
							<MenuItem value={'documents'}>Documents</MenuItem>
							<MenuItem value={'sample'}>Sample</MenuItem>
						</TextField>
					</Grid>
					<Grid size={3}>
						<TextField
							label="Invoice Number"
							fullWidth
							size="small"
							{...register('customsInfo.invoiceNumber')}
							error={!!errors?.customsInfo?.invoiceNumber}
							onBlur={() => {
								trigger('customsInfo.invoiceNumber');
							}}
							helperText={errors?.customsInfo?.invoiceNumber?.message}
						/>
					</Grid>
					<Grid size={3}>
						<TextField
							label="Product Reference"
							fullWidth
							size="small"
							{...register('customsInfo.productReference')}
							error={!!errors?.customsInfo?.productReference}
							onBlur={() => {
								trigger('customsInfo.productReference');
							}}
							helperText={errors?.customsInfo?.productReference?.message}
						/>
					</Grid>
				</Grid>
			</Box>
			{fields.map((item, index) => {
				return (
					<Accordion key={item.id} disableGutters defaultExpanded>
						<AccordionSummary
							sx={{ backgroundColor: theme.palette.grey[300], my: 0 }}
							expandIcon={<ExpandCircleDownIcon />}
						>
							<Grid container direction={'row'} sx={{ width: '100%' }}>
								<Grid size={3}>
									<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
										Parcel {index + 1}
									</Typography>
								</Grid>
								{watch([
									`parcels.${index}.length`,
									`parcels.${index}.height`,
									`parcels.${index}.weight`,
									`parcels.${index}.width`
								]) &&
									getValues(`parcels.${index}.length`) > 0 && (
										<Grid container size={9} justifyContent={'flex-end'}>
											<Grid size={5}>
												<Typography variant="subtitle1">
													Dimensions: {getValues(`parcels.${index}.length`)}
													{'cm x '}
													{getValues(`parcels.${index}.width`)}
													{'cm x '}
													{getValues(`parcels.${index}.height`)}
													{' cm'}
												</Typography>
											</Grid>
											<Grid size={3}>
												<Typography variant="subtitle1">
													Volumetric:{' '}
													{calculateVolumetric(
														getValues(`parcels.${index}.length`),
														getValues(`parcels.${index}.width`),
														getValues(`parcels.${index}.height`)
													)}
													{' kg'}
												</Typography>
											</Grid>
											<Grid size={3}>
												<Typography variant="subtitle1">
													Weight: {getValues(`parcels.${index}.weight`)}
													{' kg'}
												</Typography>
											</Grid>
										</Grid>
									)}
							</Grid>
						</AccordionSummary>
						<AccordionDetails>
							<Stack spacing={1}>
								<Grid
									container
									direction={'row'}
									alignItems={'center'}
									justifyContent={'flex-start'}
									spacing={1}
								>
									<Grid size={3}>
										<TextField
											label="Length"
											size="small"
											fullWidth
											{...register(`parcels.${index}.length`, { valueAsNumber: true })}
											error={!!errors?.parcels?.[`${index}`]?.length}
											onBlur={() => {
												trigger(`parcels.${index}.length`);
											}}
											helperText={errors?.parcels?.[`${index}`]?.length?.message}
											slotProps={{
												input: {
													endAdornment: <InputAdornment position="end">cm</InputAdornment>
												}
											}}
										/>
									</Grid>
									<Grid size={3}>
										<TextField
											label="Width"
											fullWidth
											size="small"
											slotProps={{
												input: {
													endAdornment: <InputAdornment position="end">cm</InputAdornment>
												}
											}}
											error={!!errors?.parcels?.[`${index}`]?.width}
											{...register(`parcels.${index}.width`, { valueAsNumber: true })}
											onBlur={() => {
												trigger(`parcels.${index}.width`);
											}}
											helperText={errors?.parcels?.[`${index}`]?.width?.message}
										/>
									</Grid>
									<Grid size={3}>
										<TextField
											label="Height"
											size="small"
											fullWidth
											slotProps={{
												input: {
													endAdornment: <InputAdornment position="end">cm</InputAdornment>
												}
											}}
											{...register(`parcels.${index}.height`, { valueAsNumber: true })}
											error={!!errors?.parcels?.[`${index}`]?.height}
											onBlur={() => {
												trigger(`parcels.${index}.height`);
											}}
											helperText={errors?.parcels?.[`${index}`]?.height?.message}
										/>
									</Grid>
									<Grid size={3}>
										<TextField
											label="Weight"
											size="small"
											fullWidth
											slotProps={{
												input: {
													endAdornment: <InputAdornment position="end">kg</InputAdornment>
												}
											}}
											{...register(`parcels.${index}.weight`, { valueAsNumber: true })}
											error={!!errors?.parcels?.[`${index}`]?.weight}
											onBlur={() => {
												trigger(`parcels.${index}.weight`);
											}}
											helperText={errors?.parcels?.[`${index}`]?.weight?.message}
										/>
									</Grid>
								</Grid>
								<Divider />
								<CustomsItems
									control={control}
									register={register}
									getValues={getValues}
									setValue={setValue}
									errors={errors}
									nestIndex={index}
									watch={watch}
									products={products || []}
									trigger={trigger}
									currency={currency}
								/>
							</Stack>
						</AccordionDetails>
						<AccordionActions>
							<Button
								aria-label="remove"
								variant="contained"
								color="warning"
								size="small"
								disabled={fields.length === 1}
								onClick={() => remove(index)}
							>
								Delete Parcel
							</Button>
						</AccordionActions>
					</Accordion>
				);
			})}
		</Grid>
	);
}
