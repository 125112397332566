import { MerchantDashboardState } from './MerchantDashboardContext';
import { MerchantDashboardActionTypes, MerchantDashboardActions } from './actions';

export const merchantDashboardReducer = (
	state: MerchantDashboardState,
	actions: MerchantDashboardActions
): MerchantDashboardState => {
	switch (actions.type) {
		case MerchantDashboardActionTypes.UPDATE_LOADING_STATE:
			return {
				...state,
				loading: true
			};
		case MerchantDashboardActionTypes.SET_FIRST_SHIPMENT_STATE:
			return {
				...state,
				loading: false,
				shipmentList: actions.payload,
				rowCount: 0
			};
		case MerchantDashboardActionTypes.SET_SHIPMENTS_STATE:
			return {
				...state,
				loading: false,
				shipmentList: actions.payload,
				rowCount: actions.payload.length
			};
	}
};
