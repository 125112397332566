import { Authenticator, Button, Flex, Heading, useAuthenticator, useTheme, View } from '@aws-amplify/ui-react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Avatar, Box, CircularProgress, Stack, Typography, useTheme as useMuiTheme } from '@mui/material';
import * as Sentry from '@sentry/react';
import { I18n } from 'aws-amplify';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AuthenticationContainer from '../components/Layout/AuthenticationContainer';
import Layout from '../components/Layout/Layout';
import ShipmentsTableUpdate from '../components/Merchant/Shipment/ShipmentsTable/ShipmentsTableUpdate';
import { MerchantProvider } from '../context/MerchantContext';
import merchant from '../stores/merchant';
import PostHogPageviewTracker from '../util/postHogPageviewTracker';
import { createUserProfile } from '../util/user';
import CreateBooking from './Customs/Booking';
import CustomsDashboard from './Customs/CustomsDashboard';
import CustomsPushtrackers from './Customs/CustomsPushtrackers';
import CustomsShipments from './Customs/CustomsShipments';
import ShipmentReview from './Customs/ShipmentReview';
import Home from './Home/Home';
import NotFound from './Home/NotFound';
import BulkShipment from './Merchant/BulkShipments/BulkShipments';
import CreateMerchant from './Merchant/CreateMerchant';
import Shipment from './Merchant/CreateShipment';
import MerchantProfile from './Merchant/MerchantProfile';
import Profile from './Merchant/Profile';
import SalesChannels from './Merchant/SalesChannels/SalesChannels';
import ShipmentSummary from './Merchant/ShipmentSummary';
import TrackerMerchant from './Merchant/TrackerMerchant';
import ProductLibrary from './Products/ProductLibrary';
import UsersDashboard from './Users/UsersDashboard';
import { MultiPieceShipment } from './Shipments/MultiPieceShipment';
import ShipmentsView from './Shipments/ShipmentsView';

export default function Main() {
	const muiTheme = useMuiTheme();
	const { tokens } = useTheme();
	const posthog = usePostHog();
	const [isNewUser, setIsNewUser] = useState(false);

	useEffect(() => {
		function checkShopifyParams(): void {
			let path = window.location.pathname;
			let params = new URLSearchParams(document.location.search);

			let shopifyParams = {
				hmac: params.get('hmac'),
				host: params.get('host'),
				shop: params.get('shop')
			};

			if (shopifyParams.hmac && shopifyParams.host && shopifyParams.shop && path === '/') {
				const redirectURI = process.env.REACT_APP_SHOPIFY_REDIRECT_URI;
				const clientId = process.env.REACT_APP_SHOPIFY_CLIENT_ID;
				// const scope =
				// 	'read_shipping, write_shipping, read_products, write_products, read_orders, write_orders, read_inventory, write_inventory, read_checkouts, write_checkouts';
				const scope = 'write_shipping, read_products, read_inventory';
				shopifyParams = {
					hmac: params.get('hmac'),
					host: params.get('host'),
					shop: params.get('shop')
				};
				window.location.href = `https://${shopifyParams.shop}/admin/oauth/authorize?client_id=${clientId}&scope=${scope}&redirect_uri=${redirectURI}/auth/callback/`;
				sessionStorage.setItem('shopifyParams', JSON.stringify(shopifyParams));
			}
		}
		checkShopifyParams();
	}, []);

	const { authStatus, user, route, signOut } = useAuthenticator(context => [
		context.authStatus,
		context.user,
		context.route,
		context.signOut
	]);

	if (authStatus === 'authenticated') {
		Sentry.setUser({ email: user.attributes?.email, name: user.attributes?.name });
	}

	const navigate = useNavigate();
	const { pathname } = useLocation();

	useEffect(() => {
		// ensure a user is redirected to / if they  are not logged in, this is a short term fix
		if (authStatus === 'unauthenticated' && pathname !== 'tracker') {
			navigate('/');
		}
	}, [route, navigate]);

	useEffect(() => {
		if (user && user.attributes) {
			posthog.identify(user.username, {
				email: user.attributes?.email,
				name: user.attributes?.name,
				job_title: user.attributes['custom:job_title']
			});
		}
	}, [user, route]);

	useEffect(() => {
		const onboardNewUser = async () => {
			await createUserProfile(user);
			posthog.capture('user_signed_up');
			navigate('/onboarding');
		};

		if (route === 'signUp') {
			setIsNewUser(true);
		} else if (route === 'signIn') {
			setIsNewUser(false);
		}

		if (route === 'authenticated' && isNewUser) {
			onboardNewUser();
			setIsNewUser(false);
		}
	}, [route, user, isNewUser]);

	const Logout: React.FC = () => {
		const handleLogout = async () => {
			try {
				sessionStorage.clear();
				signOut();
				navigate('/');
			} catch (error) {
				console.error('Error during logout', error);
			}
		};

		useEffect(() => {
			handleLogout();
		}, []);

		return <p>Logging out...</p>;
	};

	function authHeader(text: string) {
		return (
			<Flex
				justifyContent="center"
				alignItems="center"
				direction="column"
				padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
				color={tokens.colors.font.primary}
				textAlign="center"
				placeholder="" // Add missing properties with default values
				onPointerEnterCapture={() => {}} // Provide no-op handlers if needed
				onPointerLeaveCapture={() => {}}
			>
				<Box display={{ md: 'none' }}>
					<img style={{ height: '150px' }} src="./img/tunl-logo-blue.png" alt="TUNL logo" />
				</Box>
				<Avatar sx={{ m: 1, bgcolor: muiTheme.palette.error.main }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h4">
					{text}
				</Typography>
			</Flex>
		);
	}

	const components = {
		Header() {
			const { tokens } = useTheme();

			return <View textAlign="center" padding={tokens.space.large}></View>;
		},

		SignIn: {
			Header() {
				return authHeader('Sign in');
			},
			Footer() {
				const { toResetPassword, toSignUp } = useAuthenticator();

				return (
					<Flex
						padding={`0 ${tokens.space.xl} 0 ${tokens.space.xl}`}
						justifyContent="space-between"
						placeholder="" // Add missing properties with default values
						onPointerEnterCapture={() => {}} // Provide no-op handlers if needed
						onPointerLeaveCapture={() => {}}
					>
						<Button
							fontWeight="normal"
							padding="0px"
							onClick={toResetPassword}
							size="small"
							variation="link"
							placeholder=""
							onPointerEnterCapture={() => {}}
							onPointerLeaveCapture={() => {}}
						>
							Forgot Password?
						</Button>
						<Button
							fontWeight="normal"
							padding="0px"
							onClick={toSignUp}
							size="small"
							variation="link"
							placeholder=""
							onPointerEnterCapture={() => {}}
							onPointerLeaveCapture={() => {}}
						>
							Don't have an account? <b style={{ paddingLeft: '5px' }}>Sign up</b>
						</Button>
					</Flex>
				);
			}
		},

		SignUp: {
			Header() {
				return authHeader('Sign up');
			},
			Footer() {
				const { toSignIn } = useAuthenticator();

				return (
					<View textAlign="center">
						<Button
							fontWeight="normal"
							onClick={toSignIn}
							size="small"
							variation="link"
							placeholder=""
							onPointerEnterCapture={() => {}}
							onPointerLeaveCapture={() => {}}
						>
							Already have an account? <b style={{ paddingLeft: '5px' }}>Sign in</b>
						</Button>
					</View>
				);
			}
		},
		ConfirmSignUp: {
			Header() {
				const { tokens } = useTheme();
				return (
					<Heading
						padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
						level={3}
						placeholder=""
						onPointerEnterCapture={() => {}}
						onPointerLeaveCapture={() => {}}
					>
						Enter Information:
					</Heading>
				);
			}
		},
		SetupTOTP: {
			Header() {
				const { tokens } = useTheme();
				return (
					<Heading
						padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
						level={3}
						placeholder=""
						onPointerEnterCapture={() => {}}
						onPointerLeaveCapture={() => {}}
					>
						Enter Information:
					</Heading>
				);
			}
		},
		ConfirmSignIn: {
			Header() {
				const { tokens } = useTheme();
				return (
					<Heading
						padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
						level={3}
						placeholder=""
						onPointerEnterCapture={() => {}}
						onPointerLeaveCapture={() => {}}
					>
						Enter Information:
					</Heading>
				);
			}
		},
		ResetPassword: {
			Header() {
				const { tokens } = useTheme();
				return (
					<Heading
						padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
						level={3}
						placeholder=""
						onPointerEnterCapture={() => {}}
						onPointerLeaveCapture={() => {}}
					>
						Enter Information:
					</Heading>
				);
			}
		},
		ConfirmResetPassword: {
			Header() {
				const { tokens } = useTheme();
				return (
					<Heading
						padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
						level={3}
						placeholder=""
						onPointerEnterCapture={() => {}}
						onPointerLeaveCapture={() => {}}
					>
						Enter Information:
					</Heading>
				);
			}
		}
	};

	I18n.putVocabulariesForLanguage('en', {
		Email: 'Email Address*',
		Password: 'Password*',
		'Confirm Password': 'Confirm Password*',
		'Create Account': 'Sign up'
	});

	const formFields = {
		signUp: {
			name: {
				order: 1,
				label: 'Full Name',
				placeholder: 'Full Name'
			},
			'custom:job_title': {
				order: 2,
				placeholder: 'Job Title',
				labelHidden: true
			}
		}
	};

	function isSignIn(): boolean {
		let params = new URLSearchParams(document.location.search);
		let signUpParam = params.get('s');

		if (signUpParam && signUpParam === 'signup') {
			return false;
		}

		return true;
	}

	function getInitialState(): 'signIn' | 'signUp' | 'resetPassword' {
		let initialState: 'signIn' | 'signUp' | 'resetPassword' = 'signIn';

		if (isSignIn() === false) {
			initialState = 'signUp';
		}

		return initialState;
	}

	if (user) {
		pendo.initialize({
			visitor: {
				id: user.username || '',
				email: user.attributes?.email || '',
				firstName: merchant.name
				// lastName: '',
			},
			account: {
				id: user.username || '',
				accountName: 'TUNL'
				// payingStatus:'',
			}
		});
	}
	if (authStatus !== 'authenticated') {
		return (
			<AuthenticationContainer
				text={
					isSignIn()
						? 'Sign in and start shipping.'
						: 'Sign up for free and book your first parcel in under 2 minutes.'
				}
			>
				<Authenticator initialState={getInitialState()} formFields={formFields} components={components} />
				{(authStatus === 'configuring' || route === 'idle' || route === 'setup' || route === 'signOut') && (
					<Stack alignItems="center" pt={12}>
						<CircularProgress size="2rem" color="primary" />
						<Typography
							style={{ fontFamily: 'NeueMachinaUltraBold' }}
							pt={4}
							px={8}
							variant="h4"
							color={muiTheme.palette.primary.main}
							textAlign="center"
						>
							One moment...
						</Typography>
					</Stack>
				)}
			</AuthenticationContainer>
		);
	} else {
		return (
			<Authenticator initialState={getInitialState()} formFields={formFields} components={components}>
				{({ signOut, user }) => (
					<MerchantProvider user={user}>
						<Layout user={user} signOut={signOut}>
							<PostHogPageviewTracker />
							<Routes>
								<Route path="/" element={<Home user={user} />} />
								<Route path="/auth/callback/*" element={<SalesChannels />} />
								<Route path="/onboarding" element={<CreateMerchant user={user} />} />
								<Route path="/shipment" element={<Shipment user={user} />} />
								<Route path="/shipment/v2" element={<ShipmentsTableUpdate />} />
								<Route path="/shipment/bulk" element={<BulkShipment />} />
								<Route path="/shipment/multi-piece" element={<MultiPieceShipment />} />
								<Route path="/products" element={<ProductLibrary />} />
								<Route path="/shipment/details" element={<ShipmentSummary />} />
								<Route path="/profile" element={<Profile user={user} />} />
								<Route path="/merchant" element={<MerchantProfile user={user} />} />
								<Route path="/users" element={<UsersDashboard />} />
								<Route path="/live-rates" element={<SalesChannels />} />
								<Route path="/customs/shipments/review" element={<ShipmentReview />} />
								<Route path="/customs/booking/create" element={<CreateBooking />} />
								<Route path="/customs/dashboard" element={<CustomsDashboard />} />
								<Route path="/customs/push_trackers" element={<CustomsPushtrackers />} />
								<Route path="/customs/shipments" element={<CustomsShipments />} />
								<Route path="/Tracker" element={<TrackerMerchant />} />
								<Route path="/shipments/:id" element={<ShipmentsView />} />

								{/* Force logout path*/}
								<Route path="/logout" element={<Logout />} />

								{/* Catch all path */}
								<Route path="*" element={<NotFound />} />
							</Routes>
						</Layout>
					</MerchantProvider>
				)}
			</Authenticator>
		);
	}
}
