/**
 * Pages
 */
export const DASHBOARD = 'dashboard';
export const CUSTOM_DASHBOARD = 'custom_dashboard';
export const VIEW_SHIPMENTS = 'view_shipments';
export const CREATE_BOOKING = 'create_bookings';
export const MANUAL_TRACKERS = 'manual_trackers';
export const CREATE_SHIPMENTS = 'create_shipments';
export const PRODUCT_LIBRARY = 'product_library';
export const MERCHANT_PROFILE = 'merchant_profile';
export const MANAGE_USERS = 'manage_users';
export const LIVE_RATES = 'live_rates';
export const TRACKER = 'tracker';
export const SIDEMENU = 'side_menu';
export const SHIPMENT_TABLE = 'shipment_table';
export const HEADER = 'header';
export const CREATE_MULTIPIECE_SHIPMENTS = 'create_multipiece_shipment';

/**
 * Page-Fragments
 */
export const SHIPMENT_DETAILS = 'shipment_details';
export const SEND_FROM = 'send_from';
export const TO_ADDRESS = 'to_address';
export const PARCEL_INFO = 'parcel_info';
export const SERVICE = 'service';
export const CUSTOMS_INFO = 'customs_info';
export const CHECKOUT = 'checkout';
export const SHIPMENT_SUMMARY = 'shipment_summary';
export const PARCEL_COLLECTION = 'parcel_collection';
/**
 * Components
 */

export const ACCORDION = 'accordion';
export const AUTOCOMPLETE = 'auto_complete'
export const BUTTON = 'button';
export const CONTENT_CARD = 'content_card';
export const HEADING = 'heading';
export const LABEL = 'label';
export const KEY = 'key';
export const VALUE = 'value';
export const TEXTFIELD = 'textfield';
export const CHECKBOX = 'checkbox';
export const DROPDOWN = 'dropdown';
export const MENU_ITEM = 'menu_item';
export const FORM = 'form';
export const FORM_CONTROL = 'form_control';
export const FORM_CONTROL_LABEL = 'form_control_label';
export const RADIO_GROUP = 'radio_group';
export const RADIO_BUTTON = 'radio_button';
export const IMAGE = 'image';
export const TABLE_CONTAINER = 'table_container';
export const TABLE = 'table';
export const TABLE_HEAD = 'table_head';
export const TABLE_ROW = 'table_row';
export const TABLE_CELL = 'table_cell';
export const TABLE_BODY = 'table_body';
export const PDF_VIEWER = 'pdf_viewer';
export const LINK = 'link';
export const PAPER = 'paper';
export const TITLE = 'title';
export const ALERT = 'alert';
export const INFO = 'info';
export const WARNING = 'warning';
export const ERROR = 'error';
export const SELECT = 'select';
export const PICK_UP_OPTION = 'pick_up_option';
export const PICK_UP_TIME_SLOT = 'pick_up_time_slot';
export const DFT = 'duty_fee_tax';
export const FEE_OPTION = 'duty_fee_option';
export const SHIPMENT_PROTECTION = 'shipment_protection';
export const SIGNATURE_REQUIRED = 'signature_required';
export const SHIPMENT_COST = 'shipment_cost';
export const STEPPER  = 'stepper';
export const PAGE_NUMBER = 'page_number';
export const MODAL = 'modal';
export const EDIT_MODAL = 'edit_modal';
export const TOOLTIP = 'tooltip';
export const EDIT = 'edit';
export const DELETE = 'delete';
export const SWITCH = 'switch';
export const MERCHANT_ADDRESS = 'merchant_address';
export const GUIDED_TOUR = 'guided_tour';

export const LIST_ITEM = 'list_item';
export const ADMIN = 'admin';
export const TRACKING_STATUS = 'tracking_status';
/**
 * Common
 */
export const ECONOMY = 'economy';
export const EXPRESS = 'express';
export const CUSTOM_ITEM = 'custom_item';
export const CREATE_MERCHANT = 'create_merchant';
export const SHIPPING = 'shipping';
export const NEXT = 'next';
export const BACK = 'back';
export const RESET_FORM = 'reset_form';
export const RETURN_TO_DASHBOARD = 'return_to_dashboard';
export const MERCHANT_SELECT = 'merchant_select';
export const PRODUCT_TABLE = 'product_table';
export const TRACKING_DETAILS = 'tracking_details';

export function createElementId(arr: string[]):string{
	return arr.map(str => str?.toLowerCase().replace(/[- ]/g, '_')).join('.');
}

