import { yupResolver } from '@hookform/resolvers/yup';
import { Grid2 as Grid, TextField } from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { createElementId } from '../../../constants/id';
import { useMerchant } from '../../../context/MerchantContext';
import { ShipmentActionTypes } from '../context/actions';
import { useShipmentContext } from '../hooks/useShipmentContext';
import { addressSchema } from '../schema/Address';
import { AddressData } from '../types/address';
import LocationAutoComplete, { AddressFields } from './LocationAutoComplete';

interface Props {
	address_type: 'shipFrom' | 'shipTo';
}

export function AddressForm({ address_type }: Props) {
	const { merchantProfile, userProfile } = useMerchant();
	const { state, dispatch } = useShipmentContext();
	const isShipFromAddress = address_type === 'shipFrom';
	const {
		register,
		getValues,
		setValue,
		trigger,
		watch,
		clearErrors,
		formState: { errors }
	} = useForm<AddressData>({
		defaultValues: {
			addressType: isShipFromAddress ? 'fromAddress' : 'toAddress',
			name: isShipFromAddress ? userProfile.user_name : '',
			company: isShipFromAddress ? merchantProfile.merchant_name : '',
			email: isShipFromAddress ? merchantProfile.merchant_email : '',
			phone: isShipFromAddress ? merchantProfile.merchant_phone : '',
			street1: isShipFromAddress ? merchantProfile.merchant_street_1 : '',
			street2: isShipFromAddress ? merchantProfile.merchant_street_2 : '',
			zipPostal: isShipFromAddress ? merchantProfile.merchant_zip_postal : '',
			cityLocality: isShipFromAddress ? merchantProfile.merchant_city_locality : '',
			stateProvince: isShipFromAddress ? merchantProfile.merchant_state_province : '',
			country: isShipFromAddress ? merchantProfile.merchant_country : ''
		},
		resolver: yupResolver(addressSchema) as Resolver<AddressData>
	});
	const [canRenderAddressInputs, setCanRenderAddressInputs] = useState(false);

	useEffect(() => {
		if (isShipFromAddress) {
			setCanRenderAddressInputs(true);
			updatePageState();
		}
	}, [isShipFromAddress]);

	function updatePageState() {
		if (formValidationComplete()) {
			if (isShipFromAddress) {
				dispatch({
					type: ShipmentActionTypes.UPDATE_FORM_SECTION_STATE,
					payload: {
						sectionName: 'fromAddress',
						complete: true
					}
				});
				dispatch({
					type: ShipmentActionTypes.UPDATE_FROM_ADDRESS,
					payload: getValues()
				});
			} else {
				dispatch({
					type: ShipmentActionTypes.UPDATE_FORM_SECTION_STATE,
					payload: {
						sectionName: 'toAddress',
						complete: true
					}
				});
				dispatch({
					type: ShipmentActionTypes.UPDATE_TO_ADDRESS,
					payload: getValues()
				});
				dispatch({
					type: ShipmentActionTypes.UPDATE_TRIGGER_STATE,
					payload: {
						key: 'validAddress',
						value: true
					}
				});
			}
		} else {
			if (isShipFromAddress) {
				if (state.formSections.fromAddress.complete) {
					dispatch({
						type: ShipmentActionTypes.UPDATE_FORM_SECTION_STATE,
						payload: {
							sectionName: 'fromAddress',
							complete: false
						}
					});
				}
			} else {
				if (state.formSections.toAddress.complete) {
					dispatch({
						type: ShipmentActionTypes.UPDATE_FORM_SECTION_STATE,
						payload: {
							sectionName: 'toAddress',
							complete: false
						}
					});
					dispatch({
						type: ShipmentActionTypes.UPDATE_TRIGGER_STATE,
						payload: {
							key: 'validAddress',
							value: false
						}
					});
				}
			}
		}
	}

	function addressFound(addressFields: AddressFields) {
		setCanRenderAddressInputs(true);
		setAddressFields(addressFields);
	}

	function setAddressFields(addressFields: AddressFields) {
		// Set the form values from the address field data
		setValue('street1', addressFields.street1);
		setValue('street2', addressFields.street2);
		setValue('cityLocality', addressFields.cityLocality);
		setValue('stateProvince', addressFields.stateProvince);
		setValue('zipPostal', addressFields.zipPostal);
		setValue('country', addressFields.country);

		// Trigger form validation
		trigger('street1');
		trigger('cityLocality');
		trigger('stateProvince');
		trigger('zipPostal');
		trigger('country');

		if (allRequiredAddressFieldsFilled()) {
			clearErrors(['street1', 'cityLocality', 'stateProvince', 'zipPostal', 'country']);
		}

		updatePageState();
	}

	async function handleInputValueChange(event: React.ChangeEvent<HTMLInputElement>) {
		const name = event.target.name as keyof AddressData;
		const value = event.target.value;
		setValue(name, value);
		await trigger(name);

		updatePageState();
	}

	function formValidationComplete() {
		return allRequiredFormFieldsFilled() && isEmpty(errors);
	}

	function allRequiredFormFieldsFilled() {
		return (
			getValues('name')?.trim() !== '' &&
			getValues('email')?.trim() !== '' &&
			getValues('phone')?.trim() !== '' &&
			getValues('street1')?.trim() !== '' &&
			getValues('cityLocality')?.trim() !== '' &&
			getValues('stateProvince')?.trim() !== '' &&
			getValues('zipPostal')?.trim() !== '' &&
			getValues('country')?.trim() !== ''
		);
	}

	function allRequiredAddressFieldsFilled() {
		return (
			getValues('street1')?.trim() !== '' &&
			getValues('cityLocality')?.trim() !== '' &&
			getValues('stateProvince')?.trim() !== '' &&
			getValues('zipPostal')?.trim() !== '' &&
			getValues('country')?.trim() !== ''
		);
	}

	function renderAddressInputs() {
		return (
			<Grid container size={12} spacing={2}>
				<Grid container spacing={1} size={12}>
					<Grid size={6}>
						<TextField
							id={createElementId(['address_line_1'])}
							required
							label="Address Line 1"
							fullWidth
							{...register('street1')}
							error={!!errors?.street1}
							helperText={errors?.street1?.message}
							onChange={handleInputValueChange}
							value={watch('street1') || ''}
						/>
					</Grid>
					<Grid size={6}>
						<TextField
							id={createElementId(['address_line_2'])}
							fullWidth
							label="Address Line 2"
							{...register('street2')}
							error={!!errors?.street2}
							helperText={errors?.street2?.message || 'Apartment, building, floor(optional)'}
							onChange={handleInputValueChange}
							value={watch('street2') || ''}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={1} size={12}>
					<Grid size={3}>
						<TextField
							id={createElementId(['city'])}
							required
							label="City"
							fullWidth
							{...register('cityLocality')}
							error={!!errors?.cityLocality}
							helperText={errors?.cityLocality?.message}
							onChange={handleInputValueChange}
							value={watch('cityLocality') || ''}
						/>
					</Grid>
					<Grid size={3}>
						<TextField
							id={createElementId(['province'])}
							required
							label="State/Province"
							fullWidth
							{...register('stateProvince')}
							error={!!errors?.stateProvince}
							helperText={errors?.stateProvince?.message}
							onChange={handleInputValueChange}
							value={watch('stateProvince') || ''}
						/>
					</Grid>
					<Grid size={3}>
						<TextField
							id={createElementId(['postal_code'])}
							required
							fullWidth
							label="Zip/Postal Code"
							{...register('zipPostal')}
							error={!!errors?.zipPostal}
							helperText={errors?.zipPostal?.message}
							onChange={handleInputValueChange}
							value={watch('zipPostal') || ''}
						/>
					</Grid>
					<Grid size={3}>
						<TextField
							id={createElementId(['country_code'])}
							required
							fullWidth
							label="Country (2 letter code)"
							{...register('country')}
							error={!!errors?.country}
							helperText={errors?.country?.message}
							onChange={handleInputValueChange}
							value={watch('country') || ''}
						/>
					</Grid>
				</Grid>
			</Grid>
		);
	}

	return (
		<form style={{ width: '100%' }}>
			<Grid container spacing={2}>
				<Grid container spacing={2} size={12}>
					<Grid container spacing={1} size={12}>
						<Grid size={6}>
							<TextField
								id={createElementId(['fullname'])}
								fullWidth
								label="Full Name"
								autoFocus
								required
								{...register('name')}
								error={!!errors?.name}
								helperText={errors?.name?.message}
								onChange={handleInputValueChange}
							/>
						</Grid>
						<Grid size={6}>
							<TextField
								{...register('company')}
								id={createElementId(['company'])}
								label="Company"
								fullWidth
								error={!!errors?.company}
								helperText={errors?.company?.message}
								onChange={handleInputValueChange}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={1} size={12}>
						<Grid size={6}>
							<TextField
								id={createElementId(['email'])}
								required
								label="Email"
								fullWidth
								{...register('email')}
								error={!!errors?.email}
								helperText={errors?.email?.message}
								onChange={handleInputValueChange}
							/>
						</Grid>
						<Grid size={6}>
							<TextField
								{...register('phone')}
								id={createElementId(['phone'])}
								required
								label="Phone"
								fullWidth
								{...register('phone')}
								error={!!errors?.phone}
								helperText={errors?.phone?.message}
								onChange={handleInputValueChange}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid size={12}>
					<LocationAutoComplete addressId="to_address" required={true} onFoundAddress={addressFound} />
				</Grid>
				{canRenderAddressInputs && renderAddressInputs()}
			</Grid>
		</form>
	);
}
