import { object, string } from 'yup';

export const MOBILE_NUMBER_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,8}$/im;
export const EMAIL_REGEX = /^([A-Z0-9_+-]+\.?)*[A-Z0-9_+-]@([A-Z0-9][A-Z0-9-]*\.)+[A-Z]{2,}$/i;

export const addressSchema = object({
	addressType: string(),
	name: string().required('Full name is required'),
	email: string().matches(EMAIL_REGEX, 'Email is invalid').required('Email is required'),
	phone: string().matches(MOBILE_NUMBER_REGEX, 'Phone is invalid').required('Phone is required'),
	company: string().nullable(),
	street1: string().required('Line 1 is required'),
	street2: string().nullable(),
	cityLocality: string().required('City/Locality is required'),
	stateProvince: string().when('addressType', {
		is: (addressType: string) => addressType === 'fromAddress',
		then: schema => schema.length(2, 'State/Province has to be 2 characters').required(),
		otherwise: schema => schema.required()
	}).required('State/Province is required'),
	zipPostal: string().required('Postal code is required'),
	country: string().length(2, 'Country has to be 2 characters').required('Country is required')
});
