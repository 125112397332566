import * as React from 'react';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { throttle } from 'lodash';
import { Loader } from '@googlemaps/js-api-loader';
import { useEffect } from 'react';
import Grid from '@mui/material/Grid2';

const loader = new Loader({
	apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
	version: 'weekly',
	libraries: ['places']
});

const autocompleteService = { current: null, map: null };
let googleApi: any = null;

interface MainTextMatchedSubstrings {
	offset: number;
	length: number;
}
interface StructuredFormatting {
	main_text: string;
	secondary_text: string;
	main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
	description: string;
	place_id: string;
	structured_formatting: StructuredFormatting;
}

export interface AddressFields {
	street1: string;
	street2: string;
	zipPostal: string;
	cityLocality: string;
	stateProvince: string;
	country: string;
}

interface Props {
	addressId: string;
	required: boolean;
	onFoundAddress: (addressFields: AddressFields) => void;
}

const CONTACT_STRING_LENGTH = 6;

function isContactString(addressString: string) {
	return addressString.split(',').length === CONTACT_STRING_LENGTH;
}

function stripNonAddressParts(addressString: string) {
	const addressParts = addressString.split(',').slice(1, -1);
	return addressParts.join(',');
}

export default function LocationAutoComplete({ addressId, required, onFoundAddress }: Props) {
	const [value, setValue] = React.useState<PlaceType | null>(null);
	const [inputValue, setInputValue] = React.useState('');
	const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
	const loaded = React.useRef(false);
	const [fetchingAddress, setFetchingAddress] = React.useState(false);

	loader.load().then(google => {
		googleApi = google;
		autocompleteService.map = new googleApi.maps.Map(document.getElementById('map') as HTMLElement, {
			center: { lat: -34.397, lng: 150.644 },
			zoom: 8
		});
		loaded.current = true;
	});

	const fetch = React.useMemo(
		() =>
			throttle((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
				setFetchingAddress(true);
				const options = {
					input: request.input
				};
				(autocompleteService.current as any).getPlacePredictions(options, (predictions: PlaceType[]) => {
					if (predictions) {
						const poBoxRegex = /\b[P|p]\.?\s*[O|o]\.?\s*[B|b][O|o|0][X|x]\b/;
						const filteredPredictions = predictions.filter(prediction => {
							return !poBoxRegex.test(prediction.description);
						});
						callback(filteredPredictions);
						setFetchingAddress(false);
					} else {
						callback([]);
						setFetchingAddress(false);
					}
				});
			}, 200),
		[]
	);

	useEffect(() => {
		const addressFields = {
			street1: '',
			street2: '',
			zipPostal: '',
			cityLocality: '',
			stateProvince: '',
			country: ''
		};
		let active = true;

		if (!autocompleteService.current && googleApi) {
			const autocompleteOptions = {
				componentRestrictions: { country: 'us' }
			};
			autocompleteService.current = new googleApi.maps.places.AutocompleteService(autocompleteOptions);
		}
		if (!autocompleteService.current) {
			return undefined;
		}

		if (inputValue === '') {
			setOptions(value ? [value] : []);
			return undefined;
		}

		fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
			if (active) {
				let newOptions: readonly PlaceType[] = [];

				if (value) {
					newOptions = [value];
					new googleApi.maps.places.PlacesService(autocompleteService.map).getDetails(
						{ placeId: newOptions[0].place_id },
						(place: any) => {
							for (const component of place.address_components) {
								// @ts-ignore remove once typings fixed
								const componentType = component.types[0];

								switch (componentType) {
									case 'street_number': {
										addressFields.street1 = `${component.long_name} `;
										break;
									}

									case 'route': {
										addressFields.street1 += component.long_name;
										break;
									}

									case 'subpremise': {
										addressFields.street2 = `${component.long_name}`;
										break;
									}

									case 'postal_code': {
										addressFields.zipPostal = `${component.long_name}${addressFields.zipPostal}`;
										break;
									}
									case 'locality':
										addressFields.cityLocality = component.long_name;
										break;
									case 'postal_town':
										addressFields.cityLocality = component.long_name;
										break;
									case 'administrative_area_level_1': {
										addressFields.stateProvince =
											component.short_name === 'KZN' ? 'ZN' : component.short_name;
										break;
									}
									case 'country':
										addressFields.country = component.short_name;
										break;
								}
							}
							onFoundAddress(addressFields);
						}
					);
				}

				if (results) {
					newOptions = [...results];
				}
				setOptions(newOptions);
			}
		});

		return () => {
			active = false;
		};
	}, [value, inputValue, fetch]);

	// TODO: Disable for now. Look at this in v2.
	function handlePasteEvent(event: React.ClipboardEvent<HTMLDivElement>) {
		event.stopPropagation();
		event.preventDefault();
		event.clipboardData.items[0].getAsString(text => {
			if (!isContactString(text)) return;

			setFetchingAddress(true);
			setInputValue(stripNonAddressParts(text));
		});
	}

	return (
		<>
			<div hidden id="map"></div>
			<div>
				<Autocomplete
					id={addressId}
					getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
					options={options}
					loading={fetchingAddress}
					value={value}
					autoComplete
					filterOptions={x => x}
					forcePopupIcon={false}
					onChange={(event: any, newValue: PlaceType | null) => {
						setOptions(newValue ? [newValue, ...options] : options);
						setValue(newValue);
					}}
					onInputChange={(event, newInputValue) => {
						setInputValue(newInputValue);
					}}
					// onPaste={handlePasteEvent}
					renderInput={params => (
						<TextField
							{...params}
							required={required}
							variant="outlined"
							label="Start typing to autocomplete address..."
							fullWidth
							slotProps={{
								input: {
									...params.InputProps,
									endAdornment: (
										<>
											{fetchingAddress ? <CircularProgress color="inherit" size={20} /> : null}
											{params.InputProps.endAdornment}
										</>
									)
								}
							}}
						/>
					)}
					renderOption={(props, option) => {
						const matches = option.structured_formatting.main_text_matched_substrings;
						const parts = matches
							? parse(
									option.structured_formatting.main_text,
									matches.map((match: any) => [match.offset, match.offset + match.length])
							  )
							: [];

						return (
							<li {...props}>
								<Grid container alignItems="center">
									<Grid>
										<Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
									</Grid>
									<Grid id={addressId + '.optionItem'}>
										{parts.map((part, index) => (
											<span
												key={index}
												style={{
													fontWeight: part.highlight ? 700 : 400
												}}
											>
												{part.text}
											</span>
										))}
										<Typography variant="body2" color="text.secondary">
											{option.structured_formatting.secondary_text}
										</Typography>
									</Grid>
								</Grid>
							</li>
						);
					}}
				/>
			</div>
		</>
	);
}
