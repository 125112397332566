import {
	IconButton,
	Paper,
	Popover,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useState } from 'react';
import { DutiesAndTaxesCalculation } from '../../types/dutiesAndTaxes';
import { formatAmount } from '../../utils/utils';

interface Props {
	amount: number;
	currency: string;
	info: DutiesAndTaxesCalculation[];
}

export function DutiesAndTaxesBreakDownPopOver({ amount, currency, info }: Props) {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<div>
			<IconButton
				aria-owns={open ? 'mouse-over-popover' : undefined}
				aria-haspopup="true"
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
				size="small"
			>
				<Typography variant="body2" mr={1}>
					{`R${formatAmount(amount)}`}
				</Typography>
				<InfoOutlinedIcon />
			</IconButton>
			<Popover
				id="mouse-over-popover"
				sx={{
					pointerEvents: 'none'
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Paper>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										<Typography sx={{ fontWeight: 'bold' }}>Description</Typography>
									</TableCell>
									<TableCell>
										<Typography sx={{ fontWeight: 'bold' }}>Amount</Typography>
									</TableCell>
									<TableCell>
										<Typography sx={{ fontWeight: 'bold' }}>Formula</Typography>
									</TableCell>
									<TableCell>
										<Typography sx={{ fontWeight: 'bold' }}>Note</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{info?.map((item: DutiesAndTaxesCalculation) => {
									return (
										<TableRow>
											<TableCell>
												<Typography>{item.description}</Typography>
											</TableCell>
											<TableCell>
												<Typography sx={{ fontWeight: 'bold' }}>{`${currency}${formatAmount(
													item?.amount
												)}`}</Typography>
											</TableCell>
											<TableCell>
												<Typography>{item?.formula}</Typography>
											</TableCell>
											<TableCell>
												<Typography>{item?.note}</Typography>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Popover>
		</div>
	);
}
