import { Currency } from "../types/currency";

export const currencyMap: { [key: string]: string } = {
	ZAR: 'R',
	GBP: '£',
	EUR: '€',
	USD: '$',
	CAD: 'C$',
	AUD: 'A$'
};

export function currencyToSymbolConversion(currency: string) {
	return currencyMap[currency] || 'R';
}

export const currencies: Array<Currency> = ['ZAR', 'GBP', 'EUR', 'USD', 'CAD', 'AUD'];
