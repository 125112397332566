import { useContext } from "react";
import { ShipmentContext, ShipmentContextState } from "../context/ShipmentContext";

export function useShipmentContext(): ShipmentContextState {
	const context = useContext(ShipmentContext);
	if (!context) {
		throw new Error('The Shipment Context must be used within an ShipmentProvider');
	}

	return context;
}