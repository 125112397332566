import { DateTime } from 'luxon';

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
];

export function formatDate(date: Date) {
	const day = daysOfWeek[date.getUTCDay()];
	const dayOfMonth = date.getUTCDate().toString().padStart(2, '0');
	const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
	const year = date.getUTCFullYear();
	const hours = date.getHours().toString().padStart(2, '0');
	const minutes = date.getMinutes().toString().padStart(2, '0');
	return `${dayOfMonth}-${month}-${year}, ${day}, ${hours}:${minutes}`;
}

export const formatDateTime = (dateString: string): string => {
	// To return this format:  Wed 21 June 2023, 10:00 AM
	const date = new Date(dateString);
	const options: Intl.DateTimeFormatOptions = {
		timeZone: 'UTC',
		weekday: 'short',
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		hour: 'numeric'
	};
	const formattedDate = date.toLocaleString('en-US', options);
	return formattedDate.replace(',', '');
};

export const formatDateTimeCasual = (dateString: string): string => {
	const date = new Date(dateString);
	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'short',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit'
	};
	const formattedDate = date.toLocaleDateString('en-US', options);
	return formattedDate;
};

export const formatDateTimeShortISO = (input: Date | string | number): string => {
	let date: Date;

	// Validate or convert the input to a Date object
	if (input instanceof Date) {
		date = input;
	} else if (typeof input === 'string' || typeof input === 'number') {
		date = new Date(input); // Convert string or timestamp to Date
		if (isNaN(date.getTime())) {
			throw new Error('Invalid date input');
		}
	} else {
		throw new Error('Unsupported date input type');
	}

	// Format the Date object
	return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(
		2,
		'0'
	)} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
	// Example: "2024-12-18 17:30"
};

export function pickupDateUPS(): { today?: any; tomorrow?: any; monday?: any } {
	const date = DateTime.local();
	const weekday = date.weekday;
	const time = date.hour;

	const today = {
		morning: {
			minTime: date
				.set({
					hour: 8,
					minute: 0,
					second: 0,
					millisecond: 0
				})
				.toFormat('dd/MM/yyyy, HH:mm')
				.toLocaleString(),
			maxTime: date
				.set({
					hour: 15,
					minute: 0,
					second: 0,
					millisecond: 0
				})
				.toFormat('dd/MM/yyyy, HH:mm')
				.toLocaleString()
		},
		afternoon: {
			minTime: date
				.set({
					hour: 11,
					minute: 0,
					second: 0,
					millisecond: 0
				})
				.toFormat('dd/MM/yyyy, HH:mm')
				.toLocaleString(),
			maxTime: date
				.set({
					hour: 17,
					minute: 0,
					second: 0,
					millisecond: 0
				})
				.toFormat('dd/MM/yyyy, HH:mm')
				.toLocaleString()
		}
	};

	const tomorrow = {
		morning: {
			minTime: date
				.plus({
					days: 1
				})
				.set({
					hour: 8,
					minute: 0,
					second: 0,
					millisecond: 0
				})
				.toFormat('dd/MM/yyyy, HH:mm')
				.toLocaleString(),
			maxTime: date
				.plus({
					days: 1
				})
				.set({
					hour: 15,
					minute: 0,
					second: 0,
					millisecond: 0
				})
				.toFormat('dd/MM/yyyy, HH:mm')
				.toLocaleString()
		},
		afternoon: {
			minTime: date
				.plus({
					days: 1
				})
				.set({
					hour: 11,
					minute: 0,
					second: 0,
					millisecond: 0
				})
				.toFormat('dd/MM/yyyy, HH:mm')
				.toLocaleString(),
			maxTime: date
				.plus({
					days: 1
				})
				.set({
					hour: 17,
					minute: 0,
					second: 0,
					millisecond: 0
				})
				.toFormat('dd/MM/yyyy, HH:mm')
				.toLocaleString()
		}
	};

	const monday = {
		morning: {
			minTime: date
				.plus({
					days: weekday === 5 ? 3 : weekday === 6 ? 2 : 1
				})
				.set({
					hour: 8,
					minute: 0,
					second: 0,
					millisecond: 0
				})
				.toFormat('dd/MM/yyyy, HH:mm')
				.toLocaleString(),
			maxTime: date
				.plus({
					days: weekday === 5 ? 3 : weekday === 6 ? 2 : 1
				})
				.set({
					hour: 15,
					minute: 0,
					second: 0,
					millisecond: 0
				})
				.toFormat('dd/MM/yyyy, HH:mm')
				.toLocaleString()
		},
		afternoon: {
			minTime: date
				.plus({
					days: weekday === 5 ? 3 : weekday === 6 ? 2 : 1
				})
				.set({
					hour: 11,
					minute: 0,
					second: 0,
					millisecond: 0
				})
				.toFormat('dd/MM/yyyy, HH:mm')
				.toLocaleString(),
			maxTime: date
				.plus({
					days: weekday === 5 ? 3 : weekday === 6 ? 2 : 1
				})
				.set({
					hour: 17,
					minute: 0,
					second: 0,
					millisecond: 0
				})
				.toFormat('dd/MM/yyyy, HH:mm')
				.toLocaleString()
		}
	};

	if (weekday < 5) {
		if (time < 7) {
			return {
				today: today,
				tomorrow: tomorrow
			};
		} else if (time < 10) {
			return {
				today: {
					afternoon: today.afternoon
				},
				tomorrow: tomorrow
			};
		} else {
			return {
				tomorrow: tomorrow
			};
		}
	}

	if (weekday === 5) {
		if (time < 7) {
			return {
				today: today,
				monday: monday
			};
		} else if (time < 10) {
			return {
				today: {
					afternoon: today.afternoon
				},
				monday: monday
			};
		} else {
			return {
				monday: monday
			};
		}
	}

	if (weekday === 6 || weekday === 7) {
		return {
			monday: monday
		};
	}

	return {};
}

export function pickupDateFedex(): { today?: any; tomorrow?: any; monday?: any } {
	const date = DateTime.local();
	const weekday = date.weekday;
	const time = date.hour;

	const today = {
		morning: {
			minTime: date
				.set({
					hour: 9,
					minute: 0,
					second: 0,
					millisecond: 0
				})
				.toFormat('dd/MM/yyyy, HH:mm')
				.toLocaleString(),
			maxTime: date
				.set({
					hour: 12,
					minute: 0,
					second: 0,
					millisecond: 0
				})
				.toFormat('dd/MM/yyyy, HH:mm')
				.toLocaleString()
		},
		afternoon: {
			minTime: date
				.set({
					hour: 12,
					minute: 0,
					second: 0,
					millisecond: 0
				})
				.toFormat('dd/MM/yyyy, HH:mm')
				.toLocaleString(),
			maxTime: date
				.set({
					hour: 17,
					minute: 0,
					second: 0,
					millisecond: 0
				})
				.toFormat('dd/MM/yyyy, HH:mm')
				.toLocaleString()
		}
	};

	const tomorrow = {
		morning: {
			minTime: date
				.plus({
					days: 1
				})
				.set({
					hour: 9,
					minute: 0,
					second: 0,
					millisecond: 0
				})
				.toFormat('dd/MM/yyyy, HH:mm')
				.toLocaleString(),
			maxTime: date
				.plus({
					days: 1
				})
				.set({
					hour: 12,
					minute: 0,
					second: 0,
					millisecond: 0
				})
				.toFormat('dd/MM/yyyy, HH:mm')
				.toLocaleString()
		},
		afternoon: {
			minTime: date
				.plus({
					days: 1
				})
				.set({
					hour: 12,
					minute: 0,
					second: 0,
					millisecond: 0
				})
				.toFormat('dd/MM/yyyy, HH:mm')
				.toLocaleString(),
			maxTime: date
				.plus({
					days: 1
				})
				.set({
					hour: 17,
					minute: 0,
					second: 0,
					millisecond: 0
				})
				.toFormat('dd/MM/yyyy, HH:mm')
				.toLocaleString()
		}
	};

	const monday = {
		morning: {
			minTime: date
				.plus({
					days: weekday === 5 ? 3 : weekday === 6 ? 2 : 1
				})
				.set({
					hour: 9,
					minute: 0,
					second: 0,
					millisecond: 0
				})
				.toFormat('dd/MM/yyyy, HH:mm')
				.toLocaleString(),
			maxTime: date
				.plus({
					days: weekday === 5 ? 3 : weekday === 6 ? 2 : 1
				})
				.set({
					hour: 12,
					minute: 0,
					second: 0,
					millisecond: 0
				})
				.toFormat('dd/MM/yyyy, HH:mm')
				.toLocaleString()
		},
		afternoon: {
			minTime: date
				.plus({
					days: weekday === 5 ? 3 : weekday === 6 ? 2 : 1
				})
				.set({
					hour: 12,
					minute: 0,
					second: 0,
					millisecond: 0
				})
				.toFormat('dd/MM/yyyy, HH:mm')
				.toLocaleString(),
			maxTime: date
				.plus({
					days: weekday === 5 ? 3 : weekday === 6 ? 2 : 1
				})
				.set({
					hour: 17,
					minute: 0,
					second: 0,
					millisecond: 0
				})
				.toFormat('dd/MM/yyyy, HH:mm')
				.toLocaleString()
		}
	};

	if (weekday < 5) {
		if (time < 8) {
			return {
				today: today,
				tomorrow: tomorrow
			};
		} else if (time < 11) {
			return {
				today: {
					afternoon: today.afternoon
				},
				tomorrow: tomorrow
			};
		} else {
			return {
				tomorrow: tomorrow
			};
		}
	}

	if (weekday === 5) {
		if (time < 8) {
			return {
				today: today,
				monday: monday
			};
		} else if (time < 11) {
			return {
				today: {
					afternoon: today.afternoon
				},
				monday: monday
			};
		} else {
			return {
				monday: monday
			};
		}
	}

	if (weekday === 6 || weekday === 7) {
		return {
			monday: monday
		};
	}

	return {};
}
