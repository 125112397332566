import styled from '@emotion/styled';
import { Box, Link } from '@mui/material';
import { Shipment } from '../../../../../../src/types/shipment';
import NumberIndicator from '../../../../Common/NumberIndicator';

export interface CarrierLogoProps {
	shipment: Shipment;
}

const Img = styled('img')({
	width: '48px',
	height: '48px',
	marginTop: '4px'
});

export default function CarrierLogo(props: CarrierLogoProps) {
	const { shipment } = props;
	return (
		<Link href={shipment.tracker_url} rel="noopener" target="_blank">
			<Box position={'relative'}>
				<Img
					src={`../img/${
						shipment.source_api_carrier === 'UPSDAP' ? 'TUNL' : shipment.source_api_carrier
					}.${'png'}`}
				/>
				{shipment?.parcels?.length > 1 && (
					<NumberIndicator count={shipment?.parcels?.length} bgColor={'#acf381'} right={65} top={-10} />
				)}
			</Box>
		</Link>
	);
}
