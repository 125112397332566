import {
	Collapse,
	Divider,
	Grid2 as Grid,
	Typography
} from '@mui/material';
import { AmountSubtotals, DutiesAndTaxesCalculation } from '../../types/dutiesAndTaxes';
import { DutiesAndTaxesBreakDownPopOver } from './DutiesAndTaxesBreakDownPopOver';
import { formatAmount } from '../../utils/utils';

interface Props {
	open: boolean;
	amountSubTotals: Pick<AmountSubtotals, 'duties' | 'fees' | 'taxes'>;
	duties: DutiesAndTaxesCalculation[];
	taxes: DutiesAndTaxesCalculation[];
	fees: DutiesAndTaxesCalculation[];
}

export function DutiesAndTaxesBreakDown({ open, duties, taxes, fees, amountSubTotals }: Props) {
	return (
		<Collapse in={open} timeout="auto" unmountOnExit>
			<Grid container size={12}>
				<Grid size={6}>
					<Typography variant="subtitle1">Duties</Typography>
				</Grid>
				<Grid size={6} textAlign="end">
					<DutiesAndTaxesBreakDownPopOver amount={amountSubTotals.duties} info={duties} currency={'R'} />
				</Grid>
				<Divider style={{ width: '100%' }} />
				<Grid size={6}>
					<Typography variant="subtitle1">Fees</Typography>
				</Grid>
				<Grid size={6} textAlign="end">
					<DutiesAndTaxesBreakDownPopOver amount={amountSubTotals.fees} info={fees} currency={'R'} />
				</Grid>
				<Divider style={{ width: '100%' }} />
				<Grid size={6}>
					<Typography variant="subtitle1">Taxes</Typography>
				</Grid>
				<Grid size={6} textAlign="end">
					<DutiesAndTaxesBreakDownPopOver amount={amountSubTotals.taxes} info={taxes} currency={'R'} />
				</Grid>
				<Divider style={{ width: '100%' }} />
				<Grid size={6}>
					<Typography variant="subtitle1" fontWeight={'bold'}>
						Total
					</Typography>
				</Grid>
				<Grid size={6} textAlign="end">
					<Typography variant="subtitle1" fontWeight={'bold'}>
						{`R${formatAmount(
							Number(amountSubTotals.duties) +
								Number(amountSubTotals.fees) +
								Number(amountSubTotals.taxes)
						)}`}
					</Typography>
				</Grid>
			</Grid>
		</Collapse>
	);
}
