import { Box, Button, Divider, Grid, Paper, TextField, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useForm } from 'react-hook-form';
import { MerchantRecord } from '../../../../types/merchant';
import { SnackAlert } from '../../../../types/util';

interface ConnectShopifyProps {
	merchantProfile: MerchantRecord;
	setMerchantProfile: (value: MerchantRecord) => void;
	shopifyCarrier: boolean;
	setShopifyCarrier: (value: boolean) => void;
	setConnected: (value: boolean) => void;
	setLoading: (value: boolean) => void;
	setOpenSnackBar: (value: boolean) => void;
	setApiResponse: (value: SnackAlert) => void;
	setInitCheck: (value: boolean) => void;
}

export default function ConnectShopify(props: ConnectShopifyProps) {
	const {
		merchantProfile,
		setMerchantProfile,
		setLoading,
		shopifyCarrier,
		setShopifyCarrier,
		setConnected,
		setOpenSnackBar,
		setApiResponse,
		setInitCheck
	} = props;
	const postHogShopifyOneClickFlagEnabled = useFeatureFlagEnabled('shopify-one-click-auth-ft');
	const shopifyOneClickFlagEnabled =
		process.env.REACT_APP_ENVIRONMENT === 'prod'
			? postHogShopifyOneClickFlagEnabled
			: process.env.REACT_APP_ENVIRONMENT === 'dev' && merchantProfile.merchant_email === 'support+test@tunl.to'
			? true
			: false;

	const {
		register,
		getValues,
		formState: { errors },
		watch
	} = useForm({ mode: 'all' });

	const storeUrl = watch('shopify_store_url');
	const accessToken = watch('shopify_api_access_token');

	const handleSubmit = async () => {
		setLoading(true);
		try {
			if (shopifyOneClickFlagEnabled) {
				const redirectURI = process.env.REACT_APP_SHOPIFY_REDIRECT_URI;
				const clientId = process.env.REACT_APP_SHOPIFY_CLIENT_ID;
				// const scope =
				// 	'read_shipping, write_shipping, read_products, write_products, read_orders, write_orders, read_inventory, write_inventory, read_checkouts, write_checkouts';
				const scope = 'write_shipping, read_products, read_inventory';
				const rawShopifyStoreUrl = getValues('shopify_store_url')
				const shopifyStoreUrl = rawShopifyStoreUrl.startsWith('https://') ? rawShopifyStoreUrl : `https://${rawShopifyStoreUrl}`
				window.location.href = `${shopifyStoreUrl}/admin/oauth/authorize?client_id=${clientId}&scope=${scope}&redirect_uri=${redirectURI}/auth/callback/&state=${
					merchantProfile.partitionKey
				}`;
			}
			else if (!shopifyCarrier) {
				const formData = getValues();
				const session = await Auth.currentSession();
				const token = session.getIdToken().getJwtToken();

				const response = await fetch(`${process.env.REACT_APP_API_BASEURL}/shopify/live-rates/`, {
					method: 'POST',
					body: JSON.stringify({
						...getValues(),
						merchant_id: merchantProfile.partitionKey
					}),
					headers: {
						'Content-Type': 'application/json',
						Authorization: token
					}
				});
				const data = await response.json();
				if (data?.shopify.carrier_service?.active) {
					setApiResponse({ type: 'success', message: 'Success' });
					setOpenSnackBar(true);
					setShopifyCarrier(true);
					setConnected(true);
					setMerchantProfile(data.merchant);
					setInitCheck(true);
					sessionStorage.setItem('merchant', JSON.stringify(data.merchant));
				} else if (data?.errors?.base) {
					setApiResponse({
						type: 'error',
						message: data.errors.base.map((error: string) => error)
					});
					setOpenSnackBar(true);
					setShopifyCarrier(false);
				} else if (data?.errors) {
					setApiResponse({
						type: 'error',
						message: data?.errors
					});
					setOpenSnackBar(true);
					setShopifyCarrier(false);
				} else if (data?.message) {
					setApiResponse({ type: 'error', message: 'Shopify Store URL is Invalid' });
					setOpenSnackBar(true);
					setShopifyCarrier(false);
				}
				setLoading(false);
			}
		} catch (error: any) {
			setApiResponse({ type: 'error', message: 'Something went wrong' });
			setOpenSnackBar(true);
			setShopifyCarrier(false);
			setLoading(false);
		}
	};

	return (
		<Grid item xs={10} textAlign="left">
			<Paper elevation={3} sx={{ maxWidth: 600, flexGrow: 1 }}>
				<Grid container direction="column" p={2} spacing={2}>
					<Grid item>
						<Box display="flex" alignItems="center">
							<img
								srcSet={`./img/shopify-logo.png`}
								src={`./img/shopify-logo.png`}
								alt="Shopify Logo"
								loading="lazy"
								style={{ marginLeft: '0px', width: '72px', height: '72px' }}
							/>
							<Typography variant="h5">Connected Shopify</Typography>
						</Box>
						<Divider />
					</Grid>
					<Grid item>
						<Typography variant="body1">
							To connect your Shopify store follow{' '}
							<a href="https://help.tunl.to/en/article/shopify-live-rates-16gpes8/">
								{' '}
								these instructions
							</a>
							. to add the TUNL app and enter your credentials below.
						</Typography>
					</Grid>
					<Grid item>
						<Grid container direction="column" justifyContent="flex-start" spacing={1}>
							<Grid item xs={10}>
								<TextField
									label="Shopify Store Url"
									required
									fullWidth
									{...register('shopify_store_url', {
										required: 'Required'
									})}
									error={!!errors?.shopify_store_url}
									helperText={'Enter your Shopify store URL. Example: yourstore.myshopify.com'}
								/>
							</Grid>
							{!shopifyOneClickFlagEnabled && (
								<Grid item xs={10}>
									<TextField
										label="Shopify API Access-Token"
										required
										fullWidth
										{...register('shopify_api_access_token', {
											required: 'Required'
										})}
										error={!!errors?.shopify_api_access_token}
										helperText={
											errors?.shopify_api_access_token
												? (errors.shopify_api_access_token.message as string)
												: null
										}
									/>
								</Grid>
							)}
						</Grid>

						<Box
							sx={{
								display: 'flex',
								justifyContent: 'flex-end',
								flexDirection: 'row'
							}}
						>
							<Button
								variant="contained"
								onClick={handleSubmit}
								color="primary"
								disabled={(!accessToken || !storeUrl) && !shopifyOneClickFlagEnabled}
							>
								<Typography>Connect</Typography>
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
}
