import { Box } from '@mui/material';

export interface NumberIndicatorProps {
	count: number;
	bgColor: string;
	top?: number;
	right?: number;
	width?: number;
	height?: number;
	marginRight?: number;
	marginTop?: number;
}

export default function NumberIndicator(props: NumberIndicatorProps) {
	const { count, bgColor, top = 0, right = 0, width = 24, height = 24, marginRight = 1, marginTop = 1 } = props;
	return (
		<Box
			position="absolute"
			top={top}
			right={right}
			bgcolor={bgColor}
			color="#007bc4"
			borderRadius="50%"
			width={width}
			height={height}
			display="flex"
			justifyContent="center"
			alignItems="center"
			fontSize="14px"
			mr={marginRight}
			mt={marginTop}
		>
			{count}
		</Box>
	);
}
