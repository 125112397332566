import { Grid2 as Grid, Typography } from '@mui/material';

interface Props {
	label: string;
	value: string;
}

export function CostBreakDownItem({ label, value }: Props) {
	return (
		<>
			<Grid size={6}>
				<Typography variant="subtitle1">{label}</Typography>
			</Grid>
			<Grid size={6} textAlign="end">
				<Typography variant="subtitle1">{value}</Typography>
			</Grid>
		</>
	);
}
