import { Typography } from '@mui/material';
import { Shipment } from '../../../../../../src/types/shipment';

export interface ToAddressDetailsProps {
	shipment: Shipment;
}

export default function ToAddressDetails(props: ToAddressDetailsProps) {
	const { shipment } = props;
	return (
		<>
			<Typography variant="body1">{shipment.address_to_name}</Typography>
			<Typography variant="body1">{shipment.address_to_street_1},</Typography>
			{shipment.address_to_street_2 !== '' && (
				<Typography variant="body1">{shipment.address_to_street_2},</Typography>
			)}
			<Typography variant="body1" paragraph={true}>
				{shipment.address_to_city_locality}, {shipment.address_to_state_province},{' '}
				{shipment.address_to_zip_postal}, {shipment.address_to_country}
			</Typography>
		</>
	);
}
