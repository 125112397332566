import { MerchantRecord } from '../../types/merchant';
import { MockShipment } from '../../types/mockShipments';
import { CustomsItem } from '../../types/shipment';

export const mapUserServiceToRatingService = (userServiceInput: string): string => {
	switch (userServiceInput) {
		case 'FEDEX_INTERNATIONAL_PRIORITY':
			return 'FEDEX_INTERNATIONAL_PRIORITY';
		case 'UPS_SAVER':
			return 'UPSSaver';
		case 'TUNL_ECONOMY':
			return 'Ground';
		default:
			return '';
	}
};

export const parseCSVToMockShipmentData = (data: string[][], merchant: MerchantRecord): MockShipment[] => {
	const headers = data[0]; // First row contains headers

	const date = new Date();
	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'long',
		day: 'numeric'
	};

	const shipmentsData: MockShipment[] = data.slice(1).map(row => {
		const customsItems: Array<CustomsItem> = [];

		for (let i = 22; i < row.length; i += 6) {
			if (row[i] !== '') {
				customsItems.push({
					description: row[i]?.trim() || '',
					hs_tariff_number: row[i + 1]?.replace(/\./g, '') || '',
					quantity: parseInt(row[i + 2]) || 0,
					weight: parseFloat(row[i + 3]) || 0,
					value: parseFloat(row[i + 4]) || 0,
					currency: row[i + 5] as CustomsItem['currency'],
					origin_country: 'ZA' // Assume origin country is consistent with address
				});
			}
		}
		// Map row data to MockShipment object
		return {
			order_number: row[0],
			address_to_name: row[1],
			address_to_company: row[2],
			address_to_street_1: row[3],
			address_to_street_2: row[4],
			address_to_city_locality: row[5],
			address_to_state_province: row[6],
			address_to_zip_postal: row[7],
			address_to_country: row[8],
			address_to_phone: row[9],
			address_to_email: row[10],
			parcel_length_cm: parseInt(row[11] || '0'),
			parcel_width_cm: parseInt(row[12] || '0'),
			parcel_height_cm: parseInt(row[13] || '0'),
			parcel_weight_kg: parseFloat(row[14] || '0'),
			service: mapUserServiceToRatingService(row[15]),
			shipping_type: row[16],
			to_return: merchant.merchant_settings.to_return,
			insurance: +row[17],
			invoice: row[18],
			product_reference: row[19],
			content_type: row[20],
			signature: JSON.parse(row[21]?.toLocaleLowerCase()),
			customs_info: {
				contents_type: row[22], // Placeholder
				customs_items: customsItems
				//non_delivery_option: 'Return to sender' // Placeholder
			},
			date_created: date.toLocaleDateString(undefined, options)
		};
	}) as MockShipment[];
	return shipmentsData;
};
