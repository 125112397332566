import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import posthog from 'posthog-js';
import { useRef } from 'react';
import { CREATE_MULTIPIECE_SHIPMENTS, HEADING, createElementId } from '../../constants/id';
import { useMerchant } from '../../context/MerchantContext';
import { AddressForm } from './components/AddressForm';
import { BuyLabel } from './components/BuyLabel/BuyLabel';
import { FormSection } from './components/FormSection';
import ParcelContainer from './components/Parcel/ParcelContainer';
import { PickUp } from './components/PickUp';
import Services from './components/Services';
import { ShipmentContext, ShipmentProvider } from './context/ShipmentContext';
import { AddressData } from './types/address';
import { formatAddressForSectionSummary } from './utils/utils';

interface Props {}

export function MultiPieceShipment({}: Props) {
	const servicesCompleteRef = useRef<boolean>(false);
	const { merchantProfile, userProfile } = useMerchant();
	posthog.capture('create_shipment_started', {
		user: userProfile.user_email,
		merchant: merchantProfile.merchant_name
	});

	return (
		<ShipmentProvider>
			<Grid
				container
				direction="row"
				alignContent="center"
				justifyContent="center"
				spacing={2}
				sx={{ marginLeft: 2, marginRight: 2 }}
			>
				<Grid size={12} sx={{ pl: 1, pt: 1 }}>
					<Typography id={createElementId([CREATE_MULTIPIECE_SHIPMENTS, HEADING])} variant="h6">
						Create multi-parcel shipment
					</Typography>
				</Grid>
				<ShipmentContext.Consumer>
					{value => {
						if (value?.state.formSections.services.complete) {
							servicesCompleteRef.current = true;
						}
						return (
							<>
								<Grid size={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}>
									<FormSection
										id="ship_from"
										title="Ship From"
										summary={formatAddressForSectionSummary(
											value?.state.fromAddress as AddressData
										)}
										expanded={false}
										complete={!!value?.state.formSections.fromAddress.complete}
									>
										<AddressForm address_type="shipFrom" />
									</FormSection>
									<FormSection
										id="ship_to"
										title="Ship To"
										summary={formatAddressForSectionSummary(
											value?.state.toAddress as AddressData,
											!!value?.state.formSections.toAddress.complete
										)}
										expanded={true}
										complete={!!value?.state.formSections.toAddress.complete}
									>
										<AddressForm address_type="shipTo" />
									</FormSection>
									<FormSection
										id="parcels"
										title="Parcel details"
										summary={''}
										expanded={true}
										complete={!!value?.state.formSections.parcels.complete}
									>
										<ParcelContainer />
									</FormSection>
								</Grid>
								<Grid size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
									<Services />
									{servicesCompleteRef.current && <PickUp />}
									{servicesCompleteRef.current && <BuyLabel />}
								</Grid>
							</>
						);
					}}
				</ShipmentContext.Consumer>
			</Grid>
		</ShipmentProvider>
	);
}
