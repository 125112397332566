import { Download } from '@mui/icons-material';
import { Box, Dialog, Grid, Link, Typography } from '@mui/material';
import {
	MRT_ColumnDef,
	MRT_ColumnFiltersState,
	MRT_PaginationState,
	MRT_TableOptions,
	MaterialReactTable,
	useMaterialReactTable
} from 'material-react-table';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosConfig } from '../../../../constants/axios';
import { useMerchant } from '../../../../context/MerchantContext';
import { MerchantDashboardActionTypes } from '../../../../pages/Merchant/MerchantDashboard/context/actions';
import { useMerchantDashboardContext } from '../../../../pages/Merchant/MerchantDashboard/hooks/useMerchantDashboardContext';
import ShipmentSummaryModal from '../../../../pages/Merchant/ShipmentSummaryModal';
import { Parcel, Shipment } from '../../../../types/shipment';
import { TrackingStatus } from '../../../../types/tracker';
import { formatDateTimeShortISO } from '../../../../util/date';
import EventStatusDropwdown from '../../../Common/EvenStatusDropdown';
import RoundedButton from '../../../Common/RoundedButton';
import CommercialInvoiceButton from '../CommercialInvoiceButton';
import CarrierLogo from './components/CarrrierLogo';
import CustomerDetails from './components/CustomerDetails';
import ParcelDetails from './components/ParcelsDetails';
import ShipmentCostDetails from './components/ShipmentCostDetails';
import ToAddressDetails from './components/ToAddressDetails';

interface MerchantShipmentsResponse {
	data: {
		message: string;
		data: {
			count: number;
			data: Array<Shipment>;
		};
	};
}

export default function ShipmentsTableUpdate() {
	const { merchantProfile } = useMerchant();
	const context = useMerchantDashboardContext();
	const navigate = useNavigate();
	const { state, dispatch } = context;
	const [data, setData] = useState<Shipment[]>([]); //
	const [openShipSummary, setOpenShipSummary] = useState(false);
	const [shipment, setShipment] = useState<Shipment | null>(null);
	const [loading, setLoading] = useState(false); //
	const [rowCount, setRowCount] = useState(0); //
	const [pagination, setPagination] = useState<MRT_PaginationState>({ pageIndex: 0, pageSize: 10 });
	const axiosInstance = axiosConfig();
	const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
	const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
	const trackingStatusList = [
		'Available For Pickup',
		'Cancelled',
		'Delivered',
		'Failure',
		'In Transit',
		'Out For Delivery',
		'Pending Cancel',
		'Pre Transit',
		'Returned'
	];

	const trackingStatusMap: Record<string, TrackingStatus> = {
		'Available For Pickup': TrackingStatus.AvailableForPickUp,
		Cancelled: TrackingStatus.Cancelled,
		Delivered: TrackingStatus.Delivered,
		Failure: TrackingStatus.Failure,
		'In Transit': TrackingStatus.InTransit,
		'Out For Delivery': TrackingStatus.OutForDelivery,
		'Pending Cancel': TrackingStatus.PendingCancel,
		'Pre Transit': TrackingStatus.PreTransit,
		Returned: TrackingStatus.ReturnToSender
	};

	function getTrackingStatus(status: string): TrackingStatus {
		return trackingStatusMap[status];
	}

	const handleFiltersChange: MRT_TableOptions<Shipment>['onColumnFiltersChange'] = updaterOrValue => {
		setColumnFilters(prev => (typeof updaterOrValue === 'function' ? updaterOrValue(prev) : updaterOrValue));

		if (debounceTimeout.current) {
			clearTimeout(debounceTimeout.current);
		}

		debounceTimeout.current = setTimeout(async () => {
			const filters = typeof updaterOrValue === 'function' ? updaterOrValue(columnFilters) : updaterOrValue;
			const filterById = (id: string) => filters?.find(item => item.id === id);

			const reference = filterById('Reference');
			const trackingCode = filterById('Tracking');
			const trackingStatus = filterById('Status');
			const addressToName = filterById('To');

			await fetchShipments(
				pagination.pageIndex * 0,
				pagination.pageSize,
				(reference?.value as string) ?? null,
				(trackingCode?.value as string) ?? null,
				getTrackingStatus(trackingStatus?.value as string) ?? null,
				(addressToName?.value as string) ?? null
			);
		}, 1500);
	};

	// Fetch shipments when pagination changes
	useEffect(() => {
		const fetchData = async (currentPage: number, currentPageSize: number) => {
			await fetchShipments(currentPage, currentPageSize);
		};
		fetchData(pagination.pageIndex, pagination.pageSize);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination.pageIndex, pagination.pageSize, merchantProfile]);

	const fetchShipments = useCallback(
		async (
			currentPage: number,
			currentPageSize: number,
			reference?: string,
			tracking?: string,
			trackingStatus?: string,
			addressToName?: string
		) => {
			try {
				dispatch({ type: MerchantDashboardActionTypes.UPDATE_LOADING_STATE });
				const params = new URLSearchParams({
					pageSize: String(currentPageSize),
					pageNumber: String(currentPage + 1),
					...(reference && { reference }),
					...(tracking && { trackingCode: tracking }),
					...(trackingStatus && { trackingStatus }),
					...(addressToName && { addressToName })
				});
				const response = await axiosInstance.get(
					`/api/v1/merchants/${merchantProfile.merchant_id}/shipments?${params.toString()}`
				);
				setData(response.data.data.data);
				if (!(!!tracking || !!reference || !!addressToName)) {
					setRowCount(response.data.data.count);
				}

				if (response.data.data.count >= 0) {
					dispatch({
						type: MerchantDashboardActionTypes.SET_SHIPMENTS_STATE,
						payload: response.data.data.data
					});
				}
			} catch (error) {
				console.error('Error fetching shipments:', error);
			} finally {
				setLoading(false);
			}
		},
		[merchantProfile.merchant_id]
	);

	const handleLabelDownload = async (parcel: Parcel) => {
		window.open(parcel.labels?.pdf_url, '_blank');
	};

	const downloadAllLabels = async (shipment: Shipment) => {
		for (const parcel of shipment?.parcels || []) {
			await new Promise(resolve => setTimeout(resolve, 200));
			handleLabelDownload(parcel);
		}
	};

	const columns = useMemo<MRT_ColumnDef<Shipment>[]>(
		() => [
			{
				accessorFn: originalRow => formatDateTimeShortISO(originalRow.created_at),
				header: 'Date',
				enableColumnFilter: false,
				minSize: 50,
				maxSize: 100,
				Cell: ({ cell }) => <Typography p={0}>{cell.getValue<string>()}</Typography>
			},
			{
				accessorFn: originalRow => originalRow.address_to_name,
				header: 'To',
				minSize: 50,
				maxSize: 100,
				Cell: ({ cell }) => <Typography p={0}>{cell.getValue<string>()}</Typography>
			},
			{
				accessorFn: originalRow => originalRow.shipment_reference,
				header: 'Reference',
				minSize: 100,
				maxSize: 100,
				size: 100,
				Cell: ({ cell }) => {
					const value = cell.getValue<string>();
					const truncatedValue = value.length > 100 ? `${value.slice(0, 100)}...` : value;

					return (
						<Typography p={0} title={value}>
							{' '}
							{truncatedValue}
						</Typography>
					);
				}
			},
			{
				accessorFn: originalRow => originalRow.source_api_carrier,
				header: 'Carrier',
				enableColumnFilter: false,
				minSize: 50,
				maxSize: 100,
				Cell: ({ cell }) => {
					const shipment = cell.row.original;
					return <CarrierLogo shipment={shipment} />;
				}
			},
			{
				accessorFn: originalRow => originalRow.carrier_track_code,
				header: 'Tracking',
				minSize: 50,
				maxSize: 100,
				Cell: ({ cell }) => {
					const shipment = cell.row.original;
					return (
						<Link href={shipment.tracker_url} rel="noopener" target="_blank">
							<Typography p={0}>{shipment.carrier_track_code}</Typography>
						</Link>
					);
				}
			},
			{
				accessorFn: originalRow => originalRow.event_status,
				header: 'Status',
				minSize: 100,
				maxSize: 150,
				filterVariant: 'select',
				filterSelectOptions: trackingStatusList,
				Cell: ({ cell }) => {
					const shipment = cell.row.original;
					return (
						<div style={{ width: '150px' }}>
							<EventStatusDropwdown shipment={shipment} />
						</div>
					);
				}
			},
			{
				header: 'Details',
				minSize: 50,
				maxSize: 100,
				Cell: ({ cell }) => {
					return (
						<Box mr={1}>
							<RoundedButton
								color="primary"
								disabled={false}
								handleOnClickEvent={() => {
									const shipment = cell.row.original;
									setShipment(shipment);
									if (shipment.variant !== 'MULTI') {
										setOpenShipSummary(true);
									} else {
										navigate(`/shipments/${shipment._id}`);
									}
								}}
								label="View"
							/>
						</Box>
					);
				}
			}
		],
		[]
	);

	// Pass table options to useMaterialReactTable
	const table = useMaterialReactTable({
		enableDensityToggle: false,
		manualPagination: true,
		manualFiltering: true,
		enableRowSelection: true,
		enableGlobalFilter: false,
		globalFilterModeOptions: ['fuzzy', 'startsWith'],
		data,
		columns,
		rowCount,
		createDisplayMode: 'row',
		positionActionsColumn: 'last',
		paginationDisplayMode: 'pages',
		globalFilterFn: 'contains',
		initialState: {
			density: 'compact',
			showColumnFilters: true
		},
		state: { showSkeletons: state.loading, showProgressBars: state.loading, pagination },
		muiSkeletonProps: {
			animation: 'pulse'
		},
		muiLinearProgressProps: {
			color: 'primary'
		},
		muiSelectCheckboxProps: {
			color: 'primary'
		},
		onColumnFiltersChange: handleFiltersChange,
		onPaginationChange: setPagination,
		renderDetailPanel: ({ row }) => {
			const shipment = row.original;
			return (
				<Box>
					<Grid item>
						<Grid
							container
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							sx={{
								width: '100%'
							}}
						>
							<Grid item width={'20%'}>
								<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
									To:
								</Typography>
							</Grid>
							<Grid item width={'20%'}>
								<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
									{shipment.variant !== 'MULTI' ? 'Parcel:' : 'Multi-Parcel Details:'}
								</Typography>
							</Grid>
							<Grid item width={'20%'}>
								<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
									Costs:
								</Typography>
							</Grid>
							<Grid item width={'20%'}>
								<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
									Customer Details:
								</Typography>
							</Grid>
							<Grid item width={'10%'}>
								<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
									Quick Downloads:
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Box height={10} />
					<Grid item>
						<Grid
							container
							direction="row"
							alignItems="stretch"
							justifyContent="space-between"
							sx={{
								width: '100%',
								height: '100%'
							}}
						>
							<Grid item width={'20%'}>
								<ToAddressDetails shipment={shipment} />
							</Grid>

							<Grid item width={'20%'}>
								<ParcelDetails shipment={shipment} />
							</Grid>
							<Grid item width={'20%'}>
								<ShipmentCostDetails shipment={shipment} />
							</Grid>
							<Grid item width={'20%'}>
								<CustomerDetails shipment={shipment} />
							</Grid>
							<Grid width={'10%'}>{displayActionableItems(shipment)}</Grid>
						</Grid>
					</Grid>
				</Box>
			);
		}
	});

	return (
		<>
			<Box sx={{ m: 0 }}>
				<MaterialReactTable table={table} />
			</Box>
			<Dialog
				open={openShipSummary}
				onClose={() => setOpenShipSummary(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<ShipmentSummaryModal
					shipment={shipment}
					openShipSummary={openShipSummary}
					setOpenShipSummary={setOpenShipSummary}
				/>
			</Dialog>
		</>
	);

	function displayActionableItems(shipment: Shipment) {
		return (
			<Box>
				<Grid container direction={'column'}>
					<Grid item xs={12} md={10}>
						<RoundedButton
							color="primary"
							disabled={false}
							handleOnClickEvent={() => {
								downloadAllLabels(shipment);
							}}
							icon={<Download />}
							label={shipment.variant === 'MULTI' ? 'Labels' : 'Label'}
						/>
					</Grid>
					{shipment.record_type === 'Express Shipment' && (
						<Grid item xs={12} md={10} mt={1}>
							<RoundedButton
								color="primary"
								disabled={!shipment.order_comm_invoice}
								handleOnClickEvent={() => {
									window.open(shipment?.order_comm_invoice as string, '_blank');
								}}
								icon={<Download />}
								label="Invoice"
							/>
						</Grid>
					)}
					{shipment.record_type !== 'Express Shipment' && (
						<Grid item xs={12} md={10} mt={1}>
							<CommercialInvoiceButton shipment={shipment} />
						</Grid>
					)}
				</Grid>
			</Box>
		);
	}
}
