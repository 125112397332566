import { useContext } from 'react';
import { MerchantDashboardContext, MerchantDashboardContextState } from '../context/MerchantDashboardContext';

export function useMerchantDashboardContext(): MerchantDashboardContextState {
	const context = useContext(MerchantDashboardContext);
	if (!context) {
		throw new Error('The Merchant Dashboard Context must be used within an MerchantDashboardProvider');
	}

	return context;
}
