import MerchantDashboard from '../Merchant/MerchantDashboard/MerchantDashboard';
import { MerchantDashboardProvider } from '../Merchant/MerchantDashboard/context/MerchantDashboardContext';

function Home(props: any) {
	return (
		<MerchantDashboardProvider>
			<MerchantDashboard />
		</MerchantDashboardProvider>
	);
}

export default Home;
