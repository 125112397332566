import {
	Card,
	CardMedia,
	CardContent,
	Divider,
	Typography,
	CardActions,
	Button,
	Tooltip,
	Grid2 as Grid,
	CircularProgress
} from '@mui/material';
import SnapScanPayNow from '../../../components/Common/SnapScanPayNow';
import theme from '../../../util/theme';
import { MultiPieceShipment } from '../../../types/shipment';
import { useState } from 'react';
import { axiosConfig } from '../../../constants/axios';

interface props {
	shipment: MultiPieceShipment;
	formatAmount: (amount: number) => string;
	shipmentProtection: number;
	handleCancelShipment: () => Promise<void>;
	cancelShipmentDisabled: boolean;
	loading: boolean;
	setLoading: (state: boolean) => void;
	cancelShipmentFailure: boolean;
}

export default function TotalsCard({
	shipment,
	formatAmount,
	shipmentProtection,
	handleCancelShipment,
	cancelShipmentDisabled,
	loading,
	cancelShipmentFailure
}: props) {
	const [buttonDisabled, setButtonDisabled] = useState(
		shipment.eventStatus === 'pending_cancel' || shipment.eventStatus === 'cancelled' ? true : false
	);
	const axiosInstance = axiosConfig();
	const calculateDutiesAndTaxes = (shipment: MultiPieceShipment) => {
		if (shipment.customsIncoterm === 'DDP') {
			const duties = shipment?.ratesBreakdown?.ddp?.amount_subtotals_base.duties;
			const fees = shipment?.ratesBreakdown?.ddp?.amount_subtotals_base.fees;
			const taxes = shipment?.ratesBreakdown?.ddp?.amount_subtotals_base.taxes;
			return duties + fees + taxes;
		} else {
			return 0;
		}
	};

	const triggerCancelShipment = async () => {
		handleCancelShipment();
		await axiosInstance
			.post('/shipment/updateShipmentEventStatus', {
				shipment_id: shipment.shipmentId,
				event_status: 'pending_cancel'
			})
			.catch(error => {
				console.error(error);
				if (shipment.eventStatus !== 'pending_cancel') setButtonDisabled(false);
			})
			.finally(() => {
				shipment.eventStatus === 'pending_cancel' ? setButtonDisabled(true) : setButtonDisabled(false);
			});
	};

	return (
		<Card raised={true} elevation={3}>
			<CardMedia
				component="img"
				sx={{ p: 2, height: 200, objectFit: 'scale-down' }}
				image={`../img/${
					shipment.sourceApiCarrier === 'UPSDAP'
						? 'TUNL'
						: shipment.sourceApiCarrier === 'FedEx'
						? 'FedEx'
						: shipment.sourceApiCarrier
				}.${shipment.sourceApiCarrier === 'UPSDAP' || 'UPS' ? 'png' : 'jpg'}`}
			/>
			<CardContent sx={{ backgroundColor: theme.palette.grey[100] }}>
				<Grid container direction={'row'} spacing={2} alignItems={'center'} justifyContent={'flex-start'}>
					<Grid size={12}>
						<Grid
							container
							direction={'row'}
							spacing={2}
							alignItems={'center'}
							justifyContent={'space-between'}
						>
							<Grid>Shipping:</Grid>
							<Grid>R {formatAmount(shipment.rateCharged)}</Grid>
						</Grid>
					</Grid>
					<Grid size={12}>
						<Grid
							container
							direction={'row'}
							spacing={2}
							alignItems={'center'}
							justifyContent={'space-between'}
						>
							<Grid>Shipment Protection:</Grid>
							<Grid>R {formatAmount(shipmentProtection)}</Grid>
						</Grid>
					</Grid>
					<Grid size={12}>
						<Grid
							container
							direction={'row'}
							spacing={2}
							alignItems={'center'}
							justifyContent={'space-between'}
						>
							<Grid>Delivery Confirmation:</Grid>
							<Grid>
								R {formatAmount(shipment?.deliveryConfirmation === 'ADULT_SIGNATURE' ? 50.0 : 0)}
							</Grid>
						</Grid>
					</Grid>
					<Grid size={12}>
						<Grid
							container
							direction={'row'}
							spacing={2}
							alignItems={'center'}
							justifyContent={'space-between'}
						>
							<Grid>Duties & Taxes:</Grid>
							<Grid>R {formatAmount(calculateDutiesAndTaxes(shipment))}</Grid>
						</Grid>
					</Grid>
					<Grid size={12}>
						<Divider sx={{ mb: 2 }} />
						<Grid
							container
							direction={'row'}
							spacing={2}
							alignItems={'center'}
							justifyContent={'space-between'}
						>
							<Typography variant="h5" fontWeight="bold">
								Total
							</Typography>

							<Typography variant="h5" fontWeight="bold">
								R{' '}
								{formatAmount(
									shipment.rateCharged +
										calculateDutiesAndTaxes(shipment) +
										(shipmentProtection +
											(shipment?.deliveryConfirmation === 'ADULT_SIGNATURE' ? 50.0 : 0))
								)}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
			<CardActions sx={{ justifyContent: 'flex-end', backgroundColor: theme.palette.grey[100] }}>
				<SnapScanPayNow
					shipment={shipment}
					shipmentProtection={shipmentProtection}
					landedCosts={calculateDutiesAndTaxes(shipment) ?? 0}
					deliveryConfirmationCost={shipment?.deliveryConfirmation === 'ADULT_SIGNATURE' ? 50.0 : 0}
					multipieceShipment={shipment}
					buttonDisabled={buttonDisabled}
				/>
				{loading ? (
					<Tooltip title={cancelShipmentDisabled ? 'Shipment cannot be canelled' : ''}>
						<span>
							<Button
								disabled={cancelShipmentDisabled || cancelShipmentFailure || buttonDisabled}
								sx={{
									backgroundColor: '#e82727',
									':hover': {
										backgroundColor: '#ff0000'
									},
									whiteSpace: 'nowrap'
								}}
								color="primary"
								variant="contained"
								size="small"
							>
								<CircularProgress size={20} color="inherit" />
							</Button>
						</span>
					</Tooltip>
				) : (
					<Tooltip
						title={
							cancelShipmentDisabled
								? 'Shipments can only be cancelled in a pre transit state within 30 days of being created'
								: ''
						}
					>
						<span>
							<Button
								disabled={buttonDisabled}
								sx={{
									backgroundColor: '#e82727',
									':hover': {
										backgroundColor: '#ff0000'
									},
									whiteSpace: 'nowrap'
								}}
								color="primary"
								variant="contained"
								size="small"
								onClick={triggerCancelShipment}
							>
								Cancel
							</Button>
						</span>
					</Tooltip>
				)}
			</CardActions>
		</Card>
	);
}
