import DeleteIcon from '@mui/icons-material/Delete';
import {
	Alert,
	Autocomplete,
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Grid2 as Grid,
	IconButton,
	InputAdornment,
	SelectChangeEvent,
	Stack,
	TextField,
	Typography
} from '@mui/material';
import { debounce, isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { axiosConfig } from '../../../../constants/axios';
import { ProductRecord } from '../../../../types/product';
import { countries } from '../../../../util/country';
import { currencyMap } from '../../../../util/currency';
import theme from '../../../../util/theme';
import { useShipmentContext } from '../../hooks/useShipmentContext';
import { ShipmentActionTypes } from '../../context/actions';
import { customsItemSchema } from '../../schema/Parcel';

// TODO fix these types, using any for now
interface Props {
	control: any;
	register: any;
	getValues: any;
	setValue: any;
	errors: any;
	watch: any;
	nestIndex: number;
	products: ProductRecord[];
	trigger: any;
	currency: string;
}

interface ProductSearchType {
	id: number;
	label: string;
}

export default function CustomsItems({
	control,
	nestIndex,
	register,
	products,
	setValue,
	getValues,
	watch,
	errors,
	trigger,
	currency
}: Props) {
	const { state, dispatch } = useShipmentContext();
	const axiosInstance = axiosConfig();
	const { fields, remove, append } = useFieldArray({
		control,
		name: `parcels.${nestIndex}.customsItems`,
		rules: { minLength: 1 }
	});

	const productOptions: readonly ProductSearchType[] = products.map((product, productIndex) => ({
		id: productIndex,
		label: product.description
	}));

	const [showHSCodeWarning, setShowHSCodeWarning] = useState({
		index: 0,
		show: false
	});

	const classifyItem = useCallback(
		(value: string, indexArray: number[]) => {
			if (!state.formSections.toAddress.complete) {
				setShowHSCodeWarning({
					index: indexArray[1],
					show: true
				});
				return;
			}

			if (showHSCodeWarning.show && showHSCodeWarning.index == indexArray[1]) {
				setShowHSCodeWarning({
					index: indexArray[1],
					show: false
				});
			}

			try {
				const hsTariffNumber = getValues(
					`parcels.${indexArray[0]}.customsItems.${indexArray[1]}.hsTariffNumber`
				);
				if (hsTariffNumber) {
					return;
				}

				axiosInstance
					.post('/zonos/zonosClassify', {
						item: {
							id: '',
							description: value
						},
						ship_to_country: state.toAddress.country
					})
					.then(response => {
						const formattedHtsCode = response.data.data?.classified_code.replace(/\./g, '');
						const description = response?.data?.data?.combined_description;
						setValue(
							`parcels.${indexArray[0]}.customsItems.${indexArray[1]}.lookUp.hsTariffNumber`,
							formattedHtsCode
						);
						setValue(
							`parcels.${indexArray[0]}.customsItems.${indexArray[1]}.lookUp.description`,
							description
						);
					});
			} catch (error) {
				console.error('API request error:', error);
			}
		},
		[state.toAddress.country, state.formSections.toAddress.complete]
	);

	const debouncedOnChange = useMemo(
		() =>
			debounce((value, indexArray) => {
				classifyItem(value, indexArray);
			}, 1000),
		[classifyItem]
	);

	useEffect(() => {
		return () => {
			debouncedOnChange.cancel();
		};
	}, [debouncedOnChange]);

	const handleDescriptionChange = (event: SelectChangeEvent) => {
		const value = event.target.value;
		const nameItem = event.target.name.split('.');
		const indexArray = [nameItem[1], nameItem[3]];
		setValue(event.target.name, value);
		debouncedOnChange(value, indexArray);
	};

	const setValuesFromProduct = (product: ProductRecord, indexArray: number[]) => {
		const parcelIndex = indexArray[0];
		const customsIndex = indexArray[1];

		const quantity = getValues(`parcels.${parcelIndex}.customsItems.${customsIndex}.quantity`);
		setValue(`parcels.${parcelIndex}.customsItems.${customsIndex}.description`, product.description);
		if (product.hts_code !== '') {
			setValue(`parcels.${parcelIndex}.customsItems.${customsIndex}.hsTariffNumber`, product.hts_code);
		}

		setValue(`parcels.${parcelIndex}.customsItems.${customsIndex}.originCountry`, product.origin_country);
		if (!isNaN(product.unit_price) && !isNaN(product.unit_weight)) {
			let weight = product.unit_weight;
			let unitPrice = product.unit_price;
			if (quantity) {
				weight = weight * quantity;
				unitPrice = unitPrice * quantity;
			}
			setValue(`parcels.${parcelIndex}.customsItems.${customsIndex}.weight`, weight);
			setValue(`parcels.${parcelIndex}.customsItems.${customsIndex}.value`, unitPrice);
		}
	};

	function isCustomsItemValid(nestIndex: number, customsIndex: number) {
		return customsItemSchema.isValidSync(getValues(`parcels.${nestIndex}.customsItems.${customsIndex}`));
	}

	async function handleInputValueChange(nestIndex: number, customsIndex: number, fieldName: string) {
		dispatch({
			type: ShipmentActionTypes.UPDATE_TRIGGER_STATE,
			payload: {
				key: 'updatingCustomsItem',
				value: true
			}
		});

		await trigger(`parcels.${nestIndex}.customsItems.${customsIndex}.${fieldName}`);
		if (fieldName === 'weight') {
			await trigger(`parcels.${nestIndex}.customsItems`);
		}

		let validCustomsItems = false;

		if (isEmpty(errors) && isCustomsItemValid(nestIndex, customsIndex)) {
			validCustomsItems = true;
		}

		dispatch({
			type: ShipmentActionTypes.UPDATE_TRIGGER_STATE,
			payload: {
				key: 'validCustomsItems',
				value: validCustomsItems
			}
		});
		dispatch({
			type: ShipmentActionTypes.UPDATE_TRIGGER_STATE,
			payload: {
				key: 'updatingCustomsItem',
				value: false
			}
		});
	}

	return (
		<Stack spacing={1}>
			<Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
				<Typography gutterBottom variant="h5" component="div">
					Parcel {nestIndex + 1} Contents
				</Typography>
				<Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }} spacing={2}>
					<Button
						variant="contained"
						size="small"
						onClick={() => {
							append({
								description: '',
								quantity: 0,
								weight: 0,
								value: 0,
								hsTariffNumber: '',
								originCountry: 'ZA',
								lookUp: {
									hsTariffNumber: '',
									description: '',
									error: ''
								}
							});
						}}
					>
						Add Customs item
					</Button>
				</Stack>
			</Stack>

			{fields.map((item, customsIndex) => {
				return (
					<Card key={item.id}>
						<CardHeader
							title={'Item ' + (customsIndex + 1)}
							sx={{ backgroundColor: theme.palette.grey[200], padding: 1 }}
							disableTypography={true}
							action={
								<IconButton
									aria-label="remove"
									disabled={fields.length === 1}
									onClick={() => remove(customsIndex)}
								>
									<DeleteIcon />
								</IconButton>
							}
						/>
						<CardContent>
							<Grid container spacing={1}>
								<Grid container size={6} direction="row" spacing={1}>
									<Grid size={12}>
										<Autocomplete
											options={productOptions}
											getOptionLabel={option => option.label}
											disableClearable
											fullWidth
											disabled={products?.length === 0 ? true : false}
											onChange={(event: any, newValue: any) => {
												setValuesFromProduct(products[newValue.id], [nestIndex, customsIndex]);
											}}
											renderInput={params => (
												<TextField
													{...params}
													size="small"
													label={
														products.length === 0
															? 'No saved products.'
															: 'Search an existing product'
													}
												/>
											)}
										/>
									</Grid>
									<Grid size={12} container direction="row" spacing={1}>
										<Grid size={6}>
											<TextField
												fullWidth
												size="small"
												label="Customs description"
												{...register(
													`parcels.${nestIndex}.customsItems.${customsIndex}.description`
												)}
												error={
													!!errors?.parcels?.[nestIndex]?.customsItems?.[customsIndex]
														?.description
												}
												helperText={
													errors?.parcels?.[nestIndex]?.customsItems?.[customsIndex]
														?.description?.message
												}
												onBlur={async () => {
													await handleInputValueChange(
														nestIndex,
														customsIndex,
														'description'
													);
												}}
												slotProps={{
													inputLabel: {
														shrink: true
													}
												}}
												onChange={handleDescriptionChange}
											/>
										</Grid>
										<Grid size={6}>
											<TextField
												fullWidth
												size="small"
												label="HS Code"
												{...register(
													`parcels.${nestIndex}.customsItems.${customsIndex}.hsTariffNumber`
												)}
												error={
													!!errors?.parcels?.[nestIndex]?.customsItems?.[customsIndex]
														?.hsTariffNumber
												}
												helperText={
													errors?.parcels?.[nestIndex]?.customsItems?.[customsIndex]
														?.hsTariffNumber?.message
												}
												onBlur={async () => {
													await handleInputValueChange(
														nestIndex,
														customsIndex,
														'hsTariffNumber'
													);
												}}
												slotProps={{
													inputLabel: {
														shrink: true
													}
												}}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid container size={6} direction="row" spacing={1} alignContent={'start'}>
									<Grid size={6}>
										<TextField
											size="small"
											fullWidth
											label="Quantity"
											{...register(`parcels.${nestIndex}.customsItems.${customsIndex}.quantity`, {
												valueAsNumber: true
											})}
											error={
												!!errors?.parcels?.[nestIndex]?.customsItems?.[customsIndex]?.quantity
											}
											onBlur={async () => {
												await handleInputValueChange(nestIndex, customsIndex, 'quantity');
											}}
											helperText={
												errors?.parcels?.[nestIndex]?.customsItems?.[customsIndex]?.quantity
													?.message
											}
										/>
									</Grid>

									<Grid size={6}>
										<TextField
											size="small"
											fullWidth
											label="Total weight"
											{...register(`parcels.${nestIndex}.customsItems.${customsIndex}.weight`, {
												valueAsNumber: true
											})}
											error={
												!!errors?.parcels?.[nestIndex]?.customsItems?.[customsIndex]?.weight ||
												errors?.parcels?.[nestIndex]?.customsItems?.type ===
													'total-weight-exceeds-parcel'
											}
											helperText={
												errors?.parcels?.[nestIndex]?.customsItems?.[customsIndex]?.weight
													?.message || errors?.parcels?.[nestIndex]?.customsItems?.message
											}
											onBlur={async () => {
												await handleInputValueChange(nestIndex, customsIndex, 'weight');
											}}
											slotProps={{
												input: {
													endAdornment: <InputAdornment position="end">kg</InputAdornment>
												}
											}}
										/>
									</Grid>
									<Grid size={6}>
										<Autocomplete
											options={countries}
											defaultValue={{ code: 'ZA', label: 'South Africa', phone: '27' }}
											autoHighlight
											disableClearable
											getOptionLabel={option => option.code}
											renderOption={(props, option) => {
												const { key, ...optionProps } = props;
												return (
													<Box
														key={key}
														component="li"
														sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
														{...optionProps}
													>
														<img
															loading="lazy"
															width="20"
															srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
															src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
															alt=""
														/>
														{option.label} ({option.code})
													</Box>
												);
											}}
											renderInput={params => (
												<TextField
													{...params}
													label="Country of origin"
													size="small"
													{...register(
														`parcels.${nestIndex}.customsItems.${customsIndex}.originCountry`
													)}
													error={
														!!errors?.parcels?.[nestIndex]?.customsItems?.[customsIndex]
															?.originCountry
													}
													helperText={
														errors?.parcels?.[nestIndex]?.customsItems?.[`${customsIndex}`]
															?.originCountry?.message
													}
													onBlur={async () => {
														await handleInputValueChange(
															nestIndex,
															customsIndex,
															'originCountry'
														);
													}}
													slotProps={{
														htmlInput: {
															...params.inputProps
														}
													}}
												/>
											)}
										/>
									</Grid>
									<Grid size={6}>
										<TextField
											size="small"
											fullWidth
											label="Total value"
											slotProps={{
												input: {
													startAdornment: (
														<InputAdornment position="start">
															{currencyMap[currency]}
														</InputAdornment>
													)
												}
											}}
											error={!!errors?.parcels?.[nestIndex]?.customsItems?.[customsIndex]?.value}
											helperText={
												errors?.parcels?.[nestIndex]?.customsItems?.[customsIndex]?.value
													?.message
											}
											{...register(`parcels.${nestIndex}.customsItems.${customsIndex}.value`, {
												valueAsNumber: true
											})}
											onBlur={async () => {
												await handleInputValueChange(nestIndex, customsIndex, 'value');
											}}
										/>
									</Grid>
								</Grid>
								{showHSCodeWarning.show && showHSCodeWarning.index == customsIndex && (
									<Grid size={6}>
										<Alert
											variant="outlined"
											severity="warning"
											action={
												<Button
													color="inherit"
													size="small"
													onClick={() =>
														classifyItem(
															getValues(
																`parcels.${nestIndex}.customsItems.${customsIndex}.description`
															),
															[nestIndex, customsIndex]
														)
													}
												>
													retry
												</Button>
											}
										>
											Please complete the Ship To address and press retry to get the HS Code for
											your customs item description.
										</Alert>
									</Grid>
								)}
								{watch(`parcels.${nestIndex}.customsItems.${customsIndex}.lookUp.hsTariffNumber`) && (
									<Grid size={12}>
										<Alert
											variant="outlined"
											severity="info"
											onClose={() => {
												setValue(
													`parcels.${nestIndex}.customsItems.${customsIndex}.lookUp.hsTariffNumber`,
													''
												);
											}}
										>
											HS CODE:{' '}
											{getValues(
												`parcels.${nestIndex}.customsItems.${customsIndex}.lookUp.hsTariffNumber`
											)}
											.{' '}
											{getValues(
												`parcels.${nestIndex}.customsItems.${customsIndex}.lookUp.description`
											)}
										</Alert>
									</Grid>
								)}
							</Grid>
						</CardContent>
					</Card>
				);
			})}
		</Stack>
	);
}
