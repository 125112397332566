export function getMinDateTime(slot: number, today: any, tomorrow: any, monday: any) {
	return slot === 1
		? today.morning.minTime
		: slot === 2
		? today.afternoon.minTime
		: slot === 3
		? tomorrow.morning.minTime
		: slot === 4
		? tomorrow.afternoon.minTime
		: slot === 5
		? monday.morning.minTime
		: slot === 6
		? monday.afternoon.minTime
		: 0;
}

export function getMaxDateTime(slot: number, today: any, tomorrow: any, monday: any) {
	return slot === 1
		? today.morning.maxTime
		: slot === 2
		? today.afternoon.maxTime
		: slot === 3
		? tomorrow.morning.maxTime
		: slot === 4
		? tomorrow.afternoon.maxTime
		: slot === 5
		? monday.morning.maxTime
		: slot === 6
		? monday.afternoon.maxTime
		: 0;
}
