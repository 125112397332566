import { InputBase, styled } from "@mui/material";

export const BootstrapInput = styled(InputBase)(({ theme, error }) => ({
    'label + &': {
        marginTop: theme.spacing(3)
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${error ? 'red' : '#ced4da'}`,
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: error ? 'error' : '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
        }
    }
}));