import { Box, CircularProgress, Container, Grid2 as Grid } from '@mui/material';

interface Props {}

export function CenteredLoader({}: Props) {
	return (
		<Container
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100%',
					width: '100%'
				}}
			>
				<CircularProgress sx={{ zIndex: 1, position: 'absolute' }} />
			</Box>
		</Container>
	);
}
