import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
	Alert,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Grid2 as Grid,
	Radio,
	Stack,
	Typography,
	styled
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { axiosConfig } from '../../../constants/axios';
import theme from '../../../util/theme';
import { ShipmentActionTypes } from '../context/actions';
import { useShipmentContext } from '../hooks/useShipmentContext';
import { ParcelBase } from '../types/consignment';
import { Rates } from '../types/rates';
import { formatAmount } from '../utils/utils';
import { ServiceInformation } from './BuyLabel/ServiceInformation';
import { isParcelValid } from './Parcel/ParcelContainer';
import posthog from 'posthog-js';

interface Props {}

export default function Services({}: Props) {
	const { state, dispatch } = useShipmentContext();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [selectedService, setSelectedService] = useState('');
	const [services, setServices] = useState<Rates[]>([]);

	const axiosInstance = axiosConfig();

	const getRatesForCarriers = async () => {
		try {
			setLoading(true);

			const consignmentInput = {
				fromAddress: {
					...state.fromAddress
				},
				toAddress: {
					...state.toAddress
				},
				parcels: state.parcels.map(parcel => {
					const parcelInput: ParcelBase = {
						length: parcel.length,
						width: parcel.width,
						height: parcel.height,
						weight: parcel.weight
					};
					return parcelInput;
				})
			};

			const response = await axiosInstance.post('/api/v1/rates/express', consignmentInput);
			setServices(response?.data?.data);
			setLoading(false);
		} catch (error) {
			setError(true);
			setLoading(false);
		}
	};

	const resetOptionService = () => {
		setSelectedService('');
		dispatch({
			type: ShipmentActionTypes.UPDATE_FORM_SECTION_STATE,
			payload: {
				sectionName: 'services',
				complete: false
			}
		});
		dispatch({
			type: ShipmentActionTypes.UPDATE_RATES,
			payload: {
				selectedService: {
					carrier: '',
					partnerService: '',
					deliveryEstimate: '',
					serviceOptions: '',
					shippingRate: 0,
					image: '',
					notes: {
						type: '',
						carrierCode: '',
						amount: ''
					},
					breakdown: [],
					subtotals: {
						fuelSurcharge: 0,
						insuranceCost: 0,
						otherSurcharge: 0,
						shipping: 0
					}
				},
				returnedRates: [
					{
						carrier: '',
						partnerService: '',
						deliveryEstimate: '',
						serviceOptions: '',
						shippingRate: 0,
						image: '',
						notes: {
							type: '',
							carrierCode: '',
							amount: ''
						},
						breakdown: [],
						subtotals: {
							fuelSurcharge: 0,
							insuranceCost: 0,
							otherSurcharge: 0,
							shipping: 0
						}
					}
				]
			}
		});
	};

	useEffect(() => {
		if (
			(state.triggers.validAddress || state.triggers.validParcelDimensions) &&
			(state.triggers.validAddress || state.formSections.toAddress.complete) &&
			(state.triggers.validParcelDimensions || isParcelValid(state.parcels))
		) {
			dispatch({
				type: ShipmentActionTypes.UPDATE_TRIGGER_STATE,
				payload: {
					key: 'fetchRates',
					value: true
				}
			});
		}
	}, [state.triggers.validAddress, state.triggers.validParcelDimensions, state.formSections.toAddress.complete]);

	useEffect(() => {
		const triggerGetRates = async () => {
			if (state.triggers.fetchRates) {
				if (state.formSections.services.complete) {
					resetOptionService();
				}
				if (error) {
					setError(false);
				}
				await getRatesForCarriers().finally(() => {
					dispatch({
						type: ShipmentActionTypes.UPDATE_TRIGGER_STATE,
						payload: {
							key: 'fetchRates',
							value: false
						}
					});

					dispatch({
						type: ShipmentActionTypes.UPDATE_TRIGGER_STATE,
						payload: {
							key: 'validAddress',
							value: false
						}
					});
					dispatch({
						type: ShipmentActionTypes.UPDATE_TRIGGER_STATE,
						payload: {
							key: 'validParcelDimensions',
							value: false
						}
					});
				});
			}
		};

		triggerGetRates();
	}, [state.triggers.fetchRates]);

	const handleServiceChange = (checked: React.ChangeEvent<HTMLInputElement>, service: any) => {
		setSelectedService(checked.target.value);
		dispatch({
			type: ShipmentActionTypes.UPDATE_FORM_SECTION_STATE,
			payload: {
				sectionName: 'services',
				complete: true
			}
		});

		dispatch({
			type: ShipmentActionTypes.UPDATE_RATES,
			payload: {
				returnedRates: services,
				selectedService: service
			}
		});
		posthog.capture('service_selected', {
			service: service,
			addressFrom: state.fromAddress,
			parcels: state.parcels,
			carrier: state.rates.selectedService.notes.carrierCode,
			carrierService: state.rates.selectedService.carrier,
			rate: rateData
		});
	};

	const Img = styled('img')({
		display: 'flex',
		width: '100%',
		height: 'auto'
	});

	const rateData = services?.map((rate: any) => ({
		...rate,
		carrier: rate?.carrier,
		deliveryEstimate: 'Delivery time: 4 - 6 days',
		serviceOptions: 'Collection Included',
		shippingRate: rate?.rate,
		image: rate?.carrier === 'UPS Saver' ? 'UPS.jpg' : 'FedEx.jpg',
		notes: rate?.breakdown
			.filter((note: any) => note?.type && note?.carrierCode && note?.amount)
			.map((note: any) => ({
				type: note.type,
				carrierCode: note.carrierCode
					.replace(/[_:]/g, '')
					.replace(/^UPS api/, '')
					.replace(/^UPS/, ''),
				amount: `R ${formatAmount(note.amount)}`
			}))
	}));

	return (
		<Card sx={{ paddingBottom: '2px' }}>
			<CardHeader
				title="Shipping service"
				sx={{ backgroundColor: theme.palette.grey[100] }}
				titleTypographyProps={{ variant: 'subtitle1', fontWeight: 'bold' }}
				avatar={
					state.formSections.services.complete ? (
						<CheckCircleIcon sx={{ color: 'green' }} />
					) : (
						<CheckCircleOutlineIcon sx={{ color: 'text.secondary' }} />
					)
				}
			/>
			<CardContent sx={{ paddingBottom: '0px !important', padding: loading ? '8px' : '0' }}>
				<Grid container direction="row" size={12} justifyContent={'center'} alignContent={'center'}>
					{loading ? (
						<CircularProgress />
					) : error ? (
						<Alert variant="outlined" severity="error" sx={{ width: '100%', margin: '16px' }}>
							<Stack spacing={1}>
								<Typography sx={{ fontWeight: 'bold' }}>FAILED TO FETCH RATES</Typography>
								<Typography>Check the following:</Typography>
								<Typography sx={{ pl: 2 }}>&bull; Address information.</Typography>
								<Typography sx={{ pl: 2 }}>&bull; Parcel dimensions.</Typography>
							</Stack>
						</Alert>
					) : (
						<>
							{rateData.length > 0 ? (
								<>
									{rateData?.map((service: Rates, index: number) => (
										<Card
											key={index}
											sx={{
												width: '100%',
												borderRadius: '0px',
												overflow: 'hidden'
											}}
											elevation={0}
										>
											<CardContent sx={{ paddingBottom: '0px !important' }}>
												<Grid container direction="row" alignItems="center">
													{/* Radio Button Grid */}
													<Grid size={1}>
														<Radio
															checked={selectedService === service.carrier}
															value={service.carrier}
															onChange={e => {
																handleServiceChange(e, service);
															}}
														/>
													</Grid>
													{/* Service Information */}
													<Grid size={11}>
														<ServiceInformation rate={service} />
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									))}
								</>
							) : (
								<Alert variant="outlined" severity="warning" sx={{ width: '100%', margin: '16px' }}>
									<Stack spacing={1}>
										<Typography sx={{ fontWeight: 'bold' }}>RATES UNAVAILABLE</Typography>
										<Typography>Fix the following to get rates:</Typography>
										<Typography sx={{ pl: 2 }}>&bull; Complete address information.</Typography>
										<Typography sx={{ pl: 2 }}>&bull; Complete parcel dimensions.</Typography>
									</Stack>
								</Alert>
							)}
						</>
					)}
				</Grid>
			</CardContent>
		</Card>
	);
}
