import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { Backdrop, styled, Box, Typography } from '@mui/material';
import { axiosConfig } from '../constants/axios';
import { AmplifyUser } from '@aws-amplify/ui-react/node_modules/@aws-amplify/ui';
import { MerchantRecord } from '../types/merchant';
import { UserMerchantRecord, UserRecord } from '../types/user';
import man from '../img/svg/tunl-postman.svg';
import { useNavigate } from 'react-router-dom';
import { AppState } from '../types/util';
import posthog from 'posthog-js';

interface MerchantContextProps {
	merchantProfile: MerchantRecord;
	handleMerchantSelect: (merchant: UserMerchantRecord) => void;
	setMerchantProfile: (merchant: MerchantRecord) => void;
	setAvailableMerchants: (merchants: UserMerchantRecord[]) => void;
	setUserProfile: (user: UserRecord) => void;
	availableMerchants: UserMerchantRecord[];
	user: AmplifyUser | undefined;
	userProfile: UserRecord;
	appState: AppState;
	setAppState: (state: AppState) => void;
}

const MerchantContext = createContext<MerchantContextProps | undefined>(undefined);

interface MerchantProviderProps {
	user: AmplifyUser | undefined;
	children: ReactNode;
}

const mockedMerchant: MerchantRecord = {
	partitionKey: '',
	sortKey: '',
	store_domain: '',
	merchant_city_locality: '',
	merchant_collect: false,
	merchant_country: '',
	merchant_dropoff: false,
	merchant_email: '',
	merchant_exporters_code: '',
	merchant_id: '',
	merchant_name: '',
	merchant_personal_id: '',
	merchant_phone: '',
	merchant_state_province: '',
	merchant_street_1: '',
	merchant_street_2: '',
	merchant_vat_number: '',
	merchant_zip_postal: '',
	merchant_heard_about_from: '',
	merchant_heard_about_from_other: '',
	record_type: '',
	user_id: '',
	merchant_date_created: '',
	merchant_settings: {
		shopify_carrier: false,
		to_return: false,
		delivery_confirmation: false,
		use_own_tax_id: false,
		ddp: false
	},
	merchant_shipments_per_month: '',
	tax_identifiers: []
};

const userMock: UserRecord = {
	date_created: '',
	partitionKey: '',
	sortKey: '',
	user_email: '',
	user_name: '',
	user_id: '',
	user_merchants: [],
	record_type: 'User',
	admin_access: true
};

const AnimatedMan = styled('img')(() => ({
	width: '120px',
	height: '120px',
	animation: 'takeoff 5s ease-in-out infinite',
	'@keyframes takeoff': {
		'0%': {
			transform: 'translateX(-100vw) translateY(0)'
		},
		'30%': {
			transform: 'translateX(-50vw) translateY(-50px)'
		},
		'60%': {
			transform: 'translateX(0) translateY(-50px)'
		},
		'100%': {
			transform: 'translateX(100vw) translateY(-50px)'
		}
	}
}));

export const MerchantProvider: React.FC<MerchantProviderProps> = ({ user, children }) => {
	// set a default to get around typing issue
	const [merchantProfile, setMerchantProfile] = useState<MerchantRecord>(mockedMerchant);
	const [userProfile, setUserProfile] = useState<UserRecord>(userMock);
	const [loading, setLoading] = useState(false);
	const [updating, setUpdating] = useState(false);
	const [appState, setAppState] = useState<AppState>('init');
	const [availableMerchants, setAvailableMerchants] = useState<UserMerchantRecord[]>([]);
	const axiosInstance = axiosConfig();
	const navigate = useNavigate();

	const fetchUserProfile = async () => {
		const response = await axiosInstance.post('/user/getUser', {
			user_email: user?.attributes?.email
		});
		// gate users if they dont have a profile or a linked merchant
		if (!response.data.data.user || response.data.data.userMerchants.length === 0) {
			setAppState('onboarding');
		} else {
			const merchants = await response.data.data.userMerchants.sort(
				(a: UserMerchantRecord, b: UserMerchantRecord) => a.merchant_name.localeCompare(b.merchant_name)
			);
			setUserProfile(response.data.data.user);
			sessionStorage.setItem('user', JSON.stringify(response.data.data.user));

			if (merchants.length > 0) {
				sessionStorage.setItem('userMerchants', JSON.stringify(merchants));
				setAvailableMerchants(merchants);

				if (merchants.length > 0) {
					await handleMerchantSelect(merchants[0]);
				}
			}
			setAppState('ready');
		}
	};

	const fetchUserProfileFromSession = async () => {
		const user = JSON.parse(sessionStorage.getItem('user') ?? '');
		const merchants = JSON.parse(sessionStorage.getItem('userMerchants') ?? '') || [];
		setUserProfile(user);
		if (merchants.length > 0) {
			setAvailableMerchants(merchants);
			if (!JSON.parse(sessionStorage.getItem('merchant') ?? '')) {
				await handleMerchantSelect(availableMerchants[0]);
			} else {
				setMerchantProfile(JSON.parse(sessionStorage.getItem('merchant') ?? ''));
			}

			setAppState('ready');
		}
	};
	useEffect(() => {
		posthog.setPersonPropertiesForFlags({ merchant_name: merchantProfile.merchant_name });
	}, [merchantProfile]);

	useEffect(() => {
		const setupApp = async () => {
			try {
				if (user && appState == 'init') {
					setLoading(true);
					if (!JSON.parse(sessionStorage.getItem('user') as string)) {
						await fetchUserProfile();
					} else {
						await fetchUserProfileFromSession();
					}
					setLoading(false);
				}
			} catch (error) {
				console.log(error);
				setLoading(false);
			}
		};

		setupApp();
	}, [user]);

	useEffect(() => {
		if (appState === 'onboarding') {
			navigate('/onboarding?create_profile=true');
		}
	}, [appState]);

	const handleMerchantSelect = async (merchant: UserMerchantRecord) => {
		setUpdating(true);
		const response = await axiosInstance.post('/merchant/getMerchant', {
			merchant_id: merchant.merchant_id
		});
		const selectedMerchant = response.data.merchantRecord;
		setMerchantProfile(selectedMerchant);
		sessionStorage.setItem('merchant', JSON.stringify(selectedMerchant));
		sessionStorage.setItem('activeUserMerchant', JSON.stringify(selectedMerchant));
		setUpdating(false);
	};

	return (
		<MerchantContext.Provider
			value={{
				merchantProfile,
				handleMerchantSelect,
				setMerchantProfile,
				setAvailableMerchants,
				setUserProfile,
				availableMerchants,
				userProfile,
				user,
				appState,
				setAppState
			}}
		>
			{!loading && ['onboarding', 'ready'].includes(appState) && <>{children}</>}
			<Backdrop
				sx={{
					color: theme => theme.palette.primary.main,
					backgroundColor: theme => theme.palette.grey.A400,
					zIndex: theme => theme.zIndex.drawer + 1,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center'
				}}
				open={loading || updating}
			>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<AnimatedMan src={man} alt="man" />
				</Box>
				<Typography variant="h5" sx={{ mb: 2 }}>
					Getting ready for international shipments!
				</Typography>
			</Backdrop>
		</MerchantContext.Provider>
	);
};

export const useMerchant = (): MerchantContextProps => {
	const context = useContext(MerchantContext);
	if (!context) {
		throw new Error('useMerchant must be used within a MerchantProvider');
	}
	return context;
};
