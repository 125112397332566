export enum MerchantDashboardActionTypes {
	UPDATE_LOADING_STATE = 'UPDATE_LOADING_STATE',
	SET_FIRST_SHIPMENT_STATE = 'SET_FIRST_SHIPMENT_STATE',
	SET_SHIPMENTS_STATE = 'SET_SHIPMENTS_STATE'
}

export type MERCHANT_DASHBOARD_STATE = {
	type: MerchantDashboardActionTypes;
	payload?: any;
};

export type MerchantDashboardActions = MERCHANT_DASHBOARD_STATE;
