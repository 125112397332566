import { CloudUpload } from '@mui/icons-material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Button, CircularProgress, Container, Grid, Link, Typography, styled } from '@mui/material';
import { MockShipment } from '../../../types/mockShipments';
import { YupValidationError } from '../../../types/yupError';
import { BulkShipmentPageState } from './BulkShipments';

const Input = styled('input')({
	display: 'none'
});

export const styleContainer = {
	height: '20vh',
	bgcolor: '#f0f0f0',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center'
};

interface UploadCSVProps {
	fileInputRef: React.RefObject<HTMLInputElement>;
	onFileChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
	acceptableCSVFileTypes: string;
	onUploadClickHandler: () => void;
	inProgress: boolean | null;
	setInProgress: (input: boolean | null) => void;
	importCSVErrors: boolean;
	setImportCSVErrors: (input: boolean) => void;
	importCSVErrorMsg: string | null;
	setStepper: (step: BulkShipmentPageState) => void;
	successfulOrderImportList: Array<MockShipment> | null;
	failedOrderImportList: string[][] | null;
	fileHeaders: Array<string>;
	validatedErrors: Array<YupValidationError>;
}

export const centeredCardStyle = {
	textAlign: 'center',
	p: 4,
	maxWidth: 600,
	margin: 'auto',
	bgcolor: 'background.paper',
	borderRadius: 2,
	boxShadow: 3
};

export default function UploadCSV(props: UploadCSVProps) {
	const {
		fileInputRef,
		onFileChangeHandler,
		acceptableCSVFileTypes,
		onUploadClickHandler,
		inProgress = false,
		setInProgress,
		importCSVErrors,
		setImportCSVErrors,
		importCSVErrorMsg,
		setStepper,
		successfulOrderImportList,
		failedOrderImportList = [],
		fileHeaders,
		validatedErrors
	} = props;

	// Function to convert rows to a comma-separated string
	const convertRowsToCommaSeparatedStrings = (failedOrderImportList?: string[][] | null): string[] | undefined => {
		return failedOrderImportList?.map(row => row.join(', '));
	};

	const prependErrorsColumn = (validatedErrors: Array<YupValidationError>, csvDataArray: string[][]): string[][] => {
		// Clone the csvDataArray to avoid mutating the original
		const updatedCsvDataArray = [...csvDataArray];

		// Prepend the "Errors" heading to the file headers
		updatedCsvDataArray[0] = ['Errors', ...updatedCsvDataArray[0]];
		// Group errors by index
		const groupedErrors = validatedErrors.reduce<YupValidationError[]>((acc, curr) => {
			// Find existing entry for the same index
			const existing = acc.find(item => item.index === curr.index);
			if (existing) {
				// Append the message to the existing entry
				existing.message += ` || ${curr.message}`;
			} else {
				// Create a new entry
				acc.push({ ...curr });
			}
			return acc;
		}, []);

		// Add the "Errors" column to each row
		for (let i = 1; i < updatedCsvDataArray.length; i++) {
			updatedCsvDataArray[i] = [groupedErrors[i - 1].message, ...updatedCsvDataArray[i]];
		}
		return updatedCsvDataArray;
	};

	// Function to handle CSV download
	const handleDownloadCSV = () => {
		const csvDataArray = [fileHeaders, ...failedOrderImportList!];
		const csvErrorDataArray = prependErrorsColumn(validatedErrors, csvDataArray);
		const convertedStingArray = convertRowsToCommaSeparatedStrings(csvErrorDataArray);
		
		if (convertedStingArray) {
			const csvData = convertedStingArray.join('\n');
			const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
			const link = document.createElement('a');
			const url = URL.createObjectURL(blob);

			// Setup the link for download
			link.setAttribute('href', url);
			link.setAttribute('download', 'data.csv');
			link.click();
			URL.revokeObjectURL(url);
		} else {
			console.error('No data available for download.');
		}
	};

	return (
		<Container>
			<Box
				pt={8}
				sx={{
					minHeight: '100vh',
					alignItems: 'center'
				}}
			>
				<Container>
					{inProgress === null
						? initUploadCSVStateView()
						: inProgress === true
						? loadingUploadCSVStateView()
						: importCSVErrors === false
						? completeUploadCSVSuccessStateView()
						: completeUploadCSVFailedStateView()}
				</Container>
			</Box>
		</Container>
	);

	function initUploadCSVStateView() {
		return (
			<Box sx={centeredCardStyle}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h4" sx={{ mb: 2 }}>
							Import CSV File
						</Typography>
						<Typography variant="body1" sx={{ mb: 3 }}>
							Click <strong>“Upload CSV”</strong> to bulk purchase shipments. After uploading, you’ll be
							able to review all shipment details and costs before completing your purchase.
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<Container
							disableGutters
							sx={{
								py: 2,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center'
							}}
						>
							<label
								htmlFor="upload-button"
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									textAlign: 'center'
								}}
							>
								{/* Hidden Input for File Upload */}
								<Input
									id="upload-button"
									type="file"
									ref={fileInputRef}
									onChange={onFileChangeHandler}
									accept={acceptableCSVFileTypes}
									sx={{ display: 'none' }}
								/>
								<CloudUpload sx={{ fontSize: 80, color: 'action.active', mb: 2 }} />
								<Typography variant="body1" sx={{ mb: 2, color: 'text.secondary' }}>
									Select your CSV file to upload.
								</Typography>
								{/* Button to Trigger File Upload */}
								<Button variant="contained" component="label" htmlFor="upload-button" sx={{ mt: 2 }}>
									Upload CSV
								</Button>
							</label>
						</Container>
					</Grid>
				</Grid>
			</Box>
		);
	}

	function loadingUploadCSVStateView() {
		return (
			<Box sx={centeredCardStyle}>
				<Typography variant="h4" sx={{ mb: 2 }}>
					Importing CSV File
				</Typography>
				<Typography variant="body1" sx={{ mb: 3 }}>
					This should take a few minutes. Please do not navigate away from this page.
				</Typography>
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
					<CircularProgress />
				</Box>
			</Box>
		);
	}

	function completeUploadCSVSuccessStateView() {
		const onGotoOrdersClickHandler = () => {
			setStepper(BulkShipmentPageState.ViewOrders);
		};

		return (
			<Box sx={centeredCardStyle}>
				<Typography variant="h4" sx={{ mb: 3 }}>
					CSV Import Results
				</Typography>

				<Typography variant="h6" sx={{ mb: 2 }}>
					{(successfulOrderImportList?.length ?? 0) + (failedOrderImportList?.length ?? 0)} orders processed:
				</Typography>
				<Grid container justifyContent="center" spacing={2} sx={{ mb: 3 }}>
					<Grid item>
						<Typography variant="h6" color="success.main">
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<CheckCircleOutlineIcon fontSize="medium" sx={{ marginRight: 1 }} />
								{successfulOrderImportList?.length} successful import
							</Box>
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h6" color="warning.main">
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<WarningAmberIcon fontSize="medium" sx={{ marginRight: 1 }} />
								{failedOrderImportList?.length ?? 0} failed imports
							</Box>
						</Typography>
					</Grid>
				</Grid>

				<Typography variant="h6" sx={{ mb: 2 }}>
					Next Steps:
				</Typography>
				<Typography variant="body1" sx={{ mb: 2 }}>
					1. Review successful orders: Click <strong>"Review Successful Orders"</strong> to finalize and
					purchase shipments.
				</Typography>
				{failedOrderImportList && failedOrderImportList?.length > 0 && (
					<>
						<Typography variant="body1" sx={{ mb: 3 }}>
							2. Fix errors: Download the error file below to correct issues and re-upload your CSV.
						</Typography>

						<Link
							href="#"
							underline="none"
							sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 3 }}
						>
							<Button variant="outlined" startIcon={<CloudDownloadIcon />} onClick={handleDownloadCSV}>
								Download Error File
							</Button>
						</Link>
					</>
				)}

				<Button variant="contained" color="primary" onClick={onGotoOrdersClickHandler}>
					Review Successful Orders
				</Button>
			</Box>
		);
	}

	function completeUploadCSVFailedStateView() {
		const onBackClickHandler = () => {
			setStepper(BulkShipmentPageState.ImportCSV);
			setInProgress(null);
			setImportCSVErrors(false);
		};

		return (
			<Box sx={centeredCardStyle}>
				<Typography variant="h4" sx={{ mb: 3 }}>
					CSV Import Failed
				</Typography>
				<Typography variant="body1" sx={{ mb: 2 }}>
					There was an issue with your CSV file. Please ensure that the headers listed below are included in
					your file and try again.
				</Typography>
				<Typography variant="body1" color="error" sx={{ mb: 3 }}>
					{importCSVErrorMsg}
				</Typography>
				<CancelOutlinedIcon sx={{ fontSize: 80, color: 'error.light', mb: 3 }} />
				<Button variant="contained" color="primary" onClick={onBackClickHandler}>
					Back
				</Button>
			</Box>
		);
	}
}
