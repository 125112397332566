import { State } from './ShipmentContext';
import { ShipmentActionTypes, ShipmentActions } from './actions';

export function shipmentReducer(state: State, actions: ShipmentActions): State {
	switch (actions.type) {
		case ShipmentActionTypes.UPDATE_FORM_SECTION_STATE: {
			return {
				...state,
				formSections: {
					...state.formSections,
					[actions.payload.sectionName]: {
						complete: actions.payload.complete
					}
				}
			};
		}
		case ShipmentActionTypes.UPDATE_TRIGGER_STATE: {
			return {
				...state,
				triggers: {
					...state.triggers,
					[actions.payload.key]: actions.payload.value
				}
			};
		}
		case ShipmentActionTypes.UPDATE_FROM_ADDRESS: {
			return {
				...state,
				fromAddress: actions.payload
			};
		}
		case ShipmentActionTypes.UPDATE_TO_ADDRESS: {
			return {
				...state,
				toAddress: actions.payload
			};
		}
		case ShipmentActionTypes.UPDATE_RATES: {
			return {
				...state,
				rates: {
					returnedRates: actions.payload.returnedRates,
					selectedService: actions.payload.selectedService
				}
			};
		}
		case ShipmentActionTypes.UPDATE_PARCELS: {
			return {
				...state,
				parcels: actions.payload.parcels,
				customsInfo: actions.payload.customsInfo
			};
		}
		case ShipmentActionTypes.UPDATE_EXCHANGE_RATE: {
			return {
				...state,
				currencyExchangeRate: actions.payload
			};
		}
		case ShipmentActionTypes.UPDATE_PROTECTION_VALUE: {
			return {
				...state,
				customsTotal: actions.payload
			};
		}
		case ShipmentActionTypes.UPDATE_PICKUP: {
			return {
				...state,
				pickup: actions.payload
			};
		}
		default:
			throw Error(`Unknown action: ${actions}`);
	}
}
