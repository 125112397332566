import { Card, CardActions, CardContent, Typography } from '@mui/material';
import { MultiPieceShipment } from '../../../types/shipment';

interface props {
	shipment: MultiPieceShipment;
	type: 'To'| 'From'
}

export default function AddressDetails({ shipment, type }: props) {
	return (
		<Card sx={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }} raised={true} elevation={3}>
			<CardContent>
				<Typography gutterBottom variant="subtitle1" fontWeight={'bold'}>
					{type == 'From' ? 'Origin' : 'Destination'} details:
				</Typography>
				<Typography variant="subtitle2" fontWeight={'bold'}>
					Address
				</Typography>
				<Typography variant="subtitle2">{type === 'From' ? shipment?.addressFromStreetAddress1 : shipment?.addressToStreetAddress1}</Typography>
				<Typography variant="subtitle2">{type === 'From' ? shipment?.addressFromStreetAddress2: shipment?.addressToStreetAddress2}</Typography>
				<Typography variant="subtitle2">{type === 'From' ? shipment?.addressFromCityLocality : shipment?.addressToCityLocality}</Typography>
				<Typography variant="subtitle2">{type === 'From' ? shipment?.addressFromStateProvince : shipment?.addressToStateProvince}</Typography>
				<Typography variant="subtitle2">{type === 'From' ? shipment?.addressFromZipPostal: shipment?.addressToZipPostalCode}</Typography>
				<Typography variant="subtitle2" fontWeight={'bold'}>
					Contact information
				</Typography>
				<Typography variant="subtitle2">{type === 'From' ? shipment?.addressFromCompany : shipment?.addressToCompany}</Typography>
				<Typography variant="subtitle2">{type === 'From' ? shipment?.addressFromName : shipment?.addressToName}</Typography>
				<Typography variant="subtitle2">{type === 'From' ? shipment?.addressFromPhone : shipment?.addressToPhone}</Typography>
				<Typography variant="subtitle2">{type === 'From' ? shipment?.addressFromEmail : shipment?.addressToEmail}</Typography>
			</CardContent>
			<CardActions />
		</Card>
	);
}
