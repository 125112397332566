import { Shipment } from '../types/shipment';

export const serviceLevelMap: { [key: string]: string } = {
	EXPRESS: 'Express',
	ECONOMY: 'Economy'
};

export const pickupTypeMap: { [key: string]: string } = {
	SCHEDULED_AT_LOCATION: 'Shipment scheduled by merchant',
	SENT_TO_TUNL: 'Shipment sent to TUNL',
	NO_PICK_UP: 'Collection not required',
	PICKUP_REQUIRED: 'Scheduling collection'
};

export const shipmentCostTotal = (shipment: Shipment): string => {
	const shippingCost = parseFloat(shipment.rate_charged) || 0.0;
	const shippingProtection = parseFloat(shipment.insurance?.toString() || '0.0');
	const ddpCost = shipment?.rates_breakdown?.ddp?.landed_costs_total_rands ?? 0;
	const signatureRequiredCost = shipment.delivery_confirmation === 'ADULT_SIGNATURE' ? 50.0 : 0;

	const totalCost = (shippingCost + shippingProtection + ddpCost + signatureRequiredCost).toFixed(2);
	return totalCost;
};
