import * as yup from 'yup';

const lookupSchema = yup.object({
	hsTariffNumber: yup.string().nullable().label('HS Tariff Number'),
	description: yup.string().nullable().label('Description'),
	error: yup.string().nullable().label('Error')
});

const customsInfoSchema = yup.object({
	currency: yup.string().required('Currency is required.').label('Currency'),
	invoiceNumber: yup.string().required('Invoice Number is required.').label('Invoice Number'),
	productReference: yup.string().nullable().label('Product Reference')
});

export const customsItemSchema = yup.object({
	description: yup.string().required('Description is required.').label('Customs Item Description'),
	quantity: yup
		.number()
		.typeError('Quantity must be a number')
		.integer('Quantity must be an integer.')
		.positive('Quantity must be greater than zero.')
		.required('Quantity is required.')
		.label('Quantity'),
	weight: yup
		.number()
		.typeError('Weight must be a number')
		.positive('Weight must be greater than zero.')
		.required('Weight is required.')
		.label('Weight'),
	value: yup
		.number()
		.typeError('Value must be a number')
		.positive('Value must be greater than zero.')
		.required('Value is required.')
		.label('Value'),
	hsTariffNumber: yup.string().label('HS Tariff Number').required('HS Tariff Number required'),
	originCountry: yup
		.string()
		.length(2, 'Origin Country must be a valid 2-letter code.')
		.required('Origin Country is required.')
		.default('ZA')
		.label('Origin Country'),
	lookUp: lookupSchema.nullable()
});

export const parcelBaseSchema = yup.object({
	weight: yup
		.number()
		.typeError('Weight must be a number')
		.required('Parcel weight is required.')
		.positive('Weight must be greater than zero.')
		.label('Parcel Weight'),
	height: yup
		.number()
		.typeError('Height must be a number')
		.required('Parcel height is required.')
		.positive('Height must be greater than zero.')
		.label('Parcel Height'),
	length: yup
		.number()
		.typeError('Length must be a number')
		.required('Parcel length is required.')
		.positive('Length must be greater than zero.')
		.label('Parcel Length'),
	width: yup
		.number()
		.typeError('Width must be a number')
		.required('Parcel width is required.')
		.positive('Width must be greater than zero.')
		.label('Parcel Width')
});
const parcelSchema = parcelBaseSchema.shape({
	name: yup.string().nullable().label('Parcel Name'),
	customsItems: yup
		.array()
		.of(customsItemSchema)
		.required('At least one customs item is required.')
		.label('Customs Items')
		.test(
			'total-weight-exceeds-parcel',
			'Total customs items weight must not exceed the parcel weight',
			function (customsItems) {
				const parcelWeight = this.parent.weight;
				if (!customsItems || customsItems.length === 0) return true;
				const totalWeight = customsItems
					.filter(({ weight }) => !isNaN(weight))
					.reduce((sum, item) => sum + item.weight, 0);

				return totalWeight <= parcelWeight;
			}
		)
});

export const packagesSchema = yup.object({
	parcels: yup.array().of(parcelSchema).required('At least one parcel is required.').label('Parcels'),
	customsInfo: customsInfoSchema.required().label('Customs information')
});
