import { CopyAll, Download } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CardMedia,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid2 as Grid,
	IconButton,
	Link,
	Skeleton,
	Snackbar,
	Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useParams, useSearchParams } from 'react-router-dom';
import { axiosConfig } from '../../constants/axios';
import { MultiPieceShipment, Parcel } from '../../types/shipment';
import { currencyToSymbolConversion } from '../../util/currency';
import { formatDate } from '../../util/date';
import { serviceLevelMap } from '../../util/shipments';
import theme from '../../util/theme';
import AddressContainer from './components/AddressContainer';
import TotalsCard from './components/TotalsCard';
import {
	calculateActualWeight,
	calculateCustomsVolumetricWeight,
	calculateParcelVolumetricWeight,
	calculatedVolumetricWeight,
	formatAmount,
	getCurrency
} from './utils/utils';

export default function ShipmentView() {
	const [shipment, setShipment] = useState<MultiPieceShipment>();
	const [totalValue, setTotalValue] = useState<number>();
	const [totalActualWeight, setTotalActualWeight] = useState<number>(0);
	const [copiedToClipboard, setCopiedToClipboard] = useState(false);
	const [downloaded, setDownloaded] = useState(false);
	const [shipmentPaid, setShipmentPaid] = useState(false);
	const shipmentProtection = shipment?.insurance ? parseFloat(shipment?.insurance.toString()) : 0.0;
	const [open, setOpen] = useState(false);
	const [cancelShipmentDisabled, setCancelShipmentDisabled] = useState(false);
	const [cancelShipmentFailure, setCancelShipmentFailure] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [snackbarMessage, setSnackbarMessage] = useState<string>('');
	const [loading, setLoading] = useState(false);

	const axiosInstance = axiosConfig();
	const id = useParams<{ id: string }>().id;

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const getShipment = async () => {
		await axiosInstance
			.get(`/api/v1/shipments/${id}`)
			.then(response => {
				const shipmentResponse = response.data.data;
				setShipment(shipmentResponse);
				calculateTotals(shipmentResponse);
				getPaymentDetails(shipmentResponse.shipmentId);
				if (searchParams.get('new') === 'true') {
					handleClickOpen();
				}
			})
			.catch(e => {
				console.log(e);
			});
	};

	const getPaymentDetails = async (shipmentId: string) => {
		try {
			const response = await axiosInstance.get(
				`https://pos.snapscan.io/merchant/api/v1/payments?merchantReference=${shipmentId}`,
				{
					auth: {
						username: process.env.REACT_APP_SNAPSCAN_API_KEY!,
						password: ''
					}
				}
			);

			if (response.data[0]?.status === 'completed') {
				setShipmentPaid(true);
			}
		} catch (error) {
			console.error('Error fetching payment details:', error);
		}
	};

	useEffect(() => {
		getShipment();
	}, []);

	const calculateTotals = (shipment: MultiPieceShipment) => {
		if (shipment.parcels && shipment.variant === 'MULTI') {
			setTotalValue(
				shipment.parcels
					.flatMap((parcel: Parcel) => parcel.customs_items ?? [])
					.map((item: any) => item.value)
					.reduce((acc, value) => acc + (value ? parseFloat(value) : 0), 0)
			);
			const totalWeight = shipment.parcels
				.flatMap((parcel: Parcel) => parcel.customs_items ?? [])
				.map((item: any) => item.weight)
				.reduce((acc, value) => acc + (value ? parseFloat(value) : 0), 0);
			setTotalActualWeight(totalWeight);
		}
		if (shipment.variant === 'SINGLE') {
			const customsTotalValue = shipment.customsInfo.customs_items
				.map(items => items.value)
				.reduce((acc, value) => acc + (value ? parseFloat(value.toString()) : 0), 0);
			setTotalValue(customsTotalValue);
			setTotalActualWeight(shipment.parcelWeightKg * (shipment?.customsInfo?.customs_items.length ?? 0));
		}
	};

	const calculateParcelTotals = (shipment: MultiPieceShipment) => {
		if (shipment) {
			return parseFloat(formatAmount(totalActualWeight)) * (shipment?.parcels?.length ?? 0);
		}
		return 0;
	};

	const handleCancelShipment = async () => {
		setLoading(true);
		try {
			await axiosInstance.post('/shipment/cancelShipment', {
				shipment_id: shipment?.shipmentId
			});
			setCancelShipmentDisabled(true);
			setSnackbarMessage('Shipment cancelled.');
			setLoading(false);
		} catch (error) {
			console.error('Error cancelling shipment:', error);
			setCancelShipmentFailure(true);
			setSnackbarMessage('Failed to cancel shipment.');
			setLoading(false);
		}
	};

	const downloadAllLabels = async () => {
		// todo handle pop-up issue
		shipment?.parcels?.forEach(parcel => {
			handleLabelDownload(parcel);
		});
	};

	const handleDownload = () => {
		window.open(shipment?.orderCommInvoice as string, '_blank');
		setDownloaded(true);
	};

	const handleLabelDownload = async (parcel: Parcel) => {
		window.open(parcel.labels?.pdf_url, '_blank');
	};

	const handleCustomsCommercialDownload = (shipment: MultiPieceShipment) => {
		const mappedShipments = shipment?.sourceApiLabelUrlPdf;
		window.open(mappedShipments, '_blank');
		setDownloaded(true);
	};

	const SkeletonContainer = () => {
		return (
			<>
				<Grid
					container
					direction={'row'}
					size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
					spacing={1}
					justifyContent={'center'}
					sx={{ py: 2 }}
				>
					<Grid size={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}>
						<Skeleton height="190px" variant="rounded" />
					</Grid>
					<Grid size={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}>
						<Skeleton height="190px" variant="rounded" />
					</Grid>
					<Grid size={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}>
						<Skeleton height="190px" variant="rounded" />
					</Grid>
				</Grid>
				<Grid
					container
					direction={'row'}
					size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
					spacing={1}
					justifyContent={'center'}
					sx={{ py: 2 }}
				>
					<SkeletonCustomItems />
					<SkeletonFields />
				</Grid>
			</>
		);
	};

	const SkeletonFields = () => {
		return (
			<Grid container direction={'row'} spacing={2} size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
				<Grid size={{ xs: 12, sm: 3, md: 3, lg: 3, xl: 3 }}>
					<Skeleton width="90%" variant="text" />
				</Grid>
				<Grid size={{ xs: 12, sm: 3, md: 3, lg: 3, xl: 3 }}>
					<Skeleton width="90%" variant="text" />
				</Grid>
				<Grid size={{ xs: 12, sm: 3, md: 3, lg: 3, xl: 3 }}>
					<Skeleton width="90%" variant="text" />
				</Grid>
				<Grid size={{ xs: 12, sm: 3, md: 3, lg: 3, xl: 3 }}>
					<Skeleton width="90%" variant="text" />
				</Grid>
			</Grid>
		);
	};

	const SkeletonCustomItems = () => {
		return (
			<Grid size={12}>
				<Skeleton width="100%" height={40} variant="text" />
				<Skeleton width="100%" height={40} variant="text" />
				<Skeleton width="100%" height={40} variant="text" />
			</Grid>
		);
	};

	const SkeletonCard = () => {
		return (
			<Card raised={true}>
				<CardMedia>
					<Skeleton width={'100%'} height={200} variant="rounded" />
				</CardMedia>
				<CardContent>
					<Skeleton width="100%" variant="text" />
					<Skeleton width="100%" variant="text" />
					<Skeleton width="100%" variant="text" />
					<Skeleton width="100%" variant="text" />
					<Skeleton width="100%" variant="text" />
				</CardContent>
				<CardActions sx={{ justifyContent: 'flex-end' }}>
					<Skeleton width={50} height={20} variant="rounded" />
					<Skeleton width={50} height={20} variant="rounded" />
				</CardActions>
			</Card>
		);
	};

	const handleCopyButton = () => {
		setCopiedToClipboard(true);
		setSnackbarMessage('Copied to clipboard');
	};

	return (
		<Grid container direction={'row'} spacing={1} px={1} mt={2}>
			<Grid size={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}>
				{shipment ? (
					<Card raised={true} elevation={3}>
						<CardHeader
							action={
								<>
									<Typography variant="h6">
										Track Shipment:
										<Link href={shipment?.trackerUrl} rel="noopener" target="_blank">
											{shipment?.carrierTrackCode}
										</Link>
										<CopyToClipboard
											text={shipment?.carrierTrackCode ?? ''}
											onCopy={handleCopyButton}
										>
											<IconButton color="primary" component="span" size="large">
												<CopyAll />
											</IconButton>
										</CopyToClipboard>
									</Typography>
									<Snackbar
										open={copiedToClipboard}
										autoHideDuration={4000}
										anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
										onClose={() => setCopiedToClipboard(false)}
										message={snackbarMessage}
									/>
									<Snackbar
										open={cancelShipmentDisabled}
										autoHideDuration={4000}
										anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
										onClose={() => setCancelShipmentDisabled(false)}
										message={snackbarMessage}
									/>
									<Snackbar
										open={cancelShipmentFailure}
										autoHideDuration={4000}
										anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
										onClose={() => setCancelShipmentFailure(false)}
										message={snackbarMessage}
									/>
								</>
							}
							title={`${serviceLevelMap[shipment.serviceLevel]} Shipment`}
							subheader={`Booked on: ${formatDate(new Date(shipment.createdAt))}`}
							sx={{ pb: 0 }}
						/>

						<CardContent>
							<Grid
								container
								direction={'row'}
								size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
								spacing={2}
							>
								<AddressContainer shipment={shipment} />

								<Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
									<Card>
										<CardHeader
											action={
												<Button
													variant="contained"
													size="small"
													fullWidth
													onClick={e => {
														e.stopPropagation();
														handleDownload();
													}}
												>
													<Download />
													Commercial Invoice
												</Button>
											}
											title="Customs Info"
											sx={{ backgroundColor: theme.palette.grey[100] }}
										/>

										<CardContent>
											<Grid
												container
												direction={'row'}
												spacing={2}
												size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
											>
												<Grid size={{ xs: 12, sm: 3, md: 3, lg: 3, xl: 3 }}>
													<Typography fontSize={11}>
														Total Parcels: {shipment?.parcels?.length}
													</Typography>
												</Grid>
												<Grid size={{ xs: 12, sm: 3, md: 3, lg: 3, xl: 3 }}>
													<Typography fontSize={11}>
														Total Actual Weight:{' '}
														{shipment?.variant === 'MULTI'
															? calculateActualWeight(shipment?.parcels)
															: shipment.parcelWeightKg}
														kg
													</Typography>
												</Grid>
												<Grid size={{ xs: 12, sm: 3, md: 3, lg: 3, xl: 3 }}>
													<Typography fontSize={11}>
														Total Volumetric Weight{' '}
														{shipment?.variant === 'MULTI'
															? calculatedVolumetricWeight(shipment?.parcels ?? [])
															: calculateCustomsVolumetricWeight(shipment)}{' '}
														kg
													</Typography>
												</Grid>
												<Grid size={{ xs: 12, sm: 3, md: 3, lg: 3, xl: 3 }}>
													<Typography fontSize={11}>
														Total Customs Value:{' '}
														{`${getCurrency(shipment)} ${formatAmount(
															totalValue as number
														)}`}
													</Typography>
												</Grid>
											</Grid>

											<Grid
												container
												direction={'row'}
												spacing={1}
												alignItems={'center'}
												size={12}
											>
												{shipment?.parcels && shipment.parcels.length > 0 ? (
													<>
														{shipment?.parcels?.map(
															(parcel: Parcel, parcelIndex: number) => (
																<Grid size={12}>
																	<Card key={parcelIndex}>
																		<CardHeader
																			title={`Parcel ${parcelIndex + 1}`}
																			sx={{ pb: 0 }}
																			disableTypography={true}
																			action={
																				<Button
																					variant="contained"
																					size="small"
																					fullWidth
																					onClick={() =>
																						handleLabelDownload(parcel)
																					}
																				>
																					<Download />
																					Label
																				</Button>
																			}
																		/>
																		<CardContent>
																			<Grid
																				container
																				direction={'row'}
																				spacing={2}
																				size={12}
																				alignItems={'center'}
																			>
																				<Grid
																					size={{
																						xs: 12,
																						sm: 6,
																						md: 4,
																						lg: 4,
																						xl: 4
																					}}
																				>
																					<Typography fontSize={11}>
																						Dimensions: {parcel.height} cm x{' '}
																						{parcel.length} cm x{' '}
																						{parcel.width} cm
																					</Typography>
																				</Grid>
																				<Grid
																					size={{
																						xs: 12,
																						sm: 6,
																						md: 3,
																						lg: 3,
																						xl: 3
																					}}
																				>
																					<Typography fontSize={11}>
																						Actual Weight: {parcel.weight}{' '}
																						kg
																					</Typography>
																				</Grid>
																				<Grid
																					size={{
																						xs: 12,
																						sm: 6,
																						md: 3.6,
																						lg: 3.6,
																						xl: 3.6
																					}}
																				>
																					<Typography fontSize={11}>
																						Volumetric Weight:
																						{shipment?.parcels
																							? calculateParcelVolumetricWeight(
																									parcel
																							  )
																							: calculateCustomsVolumetricWeight(
																									shipment
																							  )}
																						kg
																					</Typography>
																				</Grid>
																			</Grid>
																			<Accordion disableGutters elevation={0}>
																				<AccordionSummary
																					sx={{
																						backgroundColor:
																							theme.palette.grey[100]
																					}}
																					expandIcon={<ExpandMoreIcon />}
																				>
																					<Typography fontWeight={'bold'}>
																						Customs Items
																					</Typography>
																				</AccordionSummary>
																				<AccordionDetails>
																					{parcel?.customs_items?.map(
																						(item: any, index: number) => (
																							<Grid
																								container
																								direction={'row'}
																								spacing={2}
																								size={12}
																								alignItems={'center'}
																								key={index}
																							>
																								<Grid
																									size={{
																										xs: 12,
																										sm: 4,
																										md: 3,
																										lg: 3,
																										xl: 3
																									}}
																								>
																									<Typography
																										fontSize={11}
																									>
																										Description:{' '}
																										{
																											item?.description
																										}
																									</Typography>
																								</Grid>
																								<Grid
																									size={{
																										xs: 12,
																										sm: 4,
																										md: 3,
																										lg: 3,
																										xl: 3
																									}}
																								>
																									<Typography
																										fontSize={11}
																									>
																										HS Code:{' '}
																										{
																											item?.hs_tariff_number
																										}
																									</Typography>
																								</Grid>
																								<Grid
																									size={{
																										xs: 12,
																										sm: 4,
																										md: 2,
																										lg: 2,
																										xl: 2
																									}}
																								>
																									<Typography
																										fontSize={11}
																									>
																										Origin Country:{' '}
																										{
																											item?.origin_country
																										}
																									</Typography>
																								</Grid>
																								<Grid
																									size={{
																										xs: 12,
																										sm: 4,
																										md: 2,
																										lg: 2,
																										xl: 2
																									}}
																								>
																									<Typography
																										fontSize={11}
																									>
																										Quantity:{' '}
																										{item?.quantity}
																									</Typography>
																								</Grid>
																								<Grid
																									size={{
																										xs: 12,
																										sm: 8,
																										md: 2,
																										lg: 2,
																										xl: 2
																									}}
																								>
																									<Typography
																										fontSize={11}
																									>
																										{`Value ${currencyToSymbolConversion(
																											item?.currency_code
																										)}
																							${formatAmount(item?.value)}`}
																									</Typography>
																								</Grid>
																							</Grid>
																						)
																					)}
																				</AccordionDetails>
																			</Accordion>
																		</CardContent>
																	</Card>
																</Grid>
															)
														)}
													</>
												) : shipment?.customsInfo?.customs_items ? (
													<Grid size={12}>
														<Accordion disableGutters elevation={0} expanded>
															<AccordionSummary
																sx={{
																	backgroundColor: theme.palette.grey[100],
																	my: 0
																}}
																expandIcon={<ExpandMoreIcon />}
															>
																<Grid
																	container
																	direction={'row'}
																	spacing={2}
																	alignItems={'center'}
																	size={12}
																	justifyContent={'space-between'}
																>
																	<Grid>
																		<Typography fontWeight={'bold'}>
																			Customs Items
																		</Typography>
																	</Grid>
																</Grid>
															</AccordionSummary>
															<AccordionDetails>
																{shipment?.customsInfo?.customs_items?.map(
																	(item: any, index: number) => (
																		<Grid
																			key={index}
																			container
																			direction={'row'}
																			spacing={2}
																			size={{
																				xs: 12,
																				sm: 12,
																				md: 12,
																				lg: 12,
																				xl: 12
																			}}
																			alignItems={'center'}
																			justifyContent={'space-between'}
																			sx={{ mx: -0.5, pt: 1 }}
																		>
																			<Grid
																				size={{
																					xs: 12,
																					sm: 4,
																					md: 3,
																					lg: 3,
																					xl: 3
																				}}
																			>
																				<Typography fontSize={11}>
																					Description: {item?.description}
																				</Typography>
																			</Grid>
																			<Grid
																				size={{
																					xs: 12,
																					sm: 4,
																					md: 3,
																					lg: 3,
																					xl: 3
																				}}
																			>
																				<Typography fontSize={11}>
																					HS Code: {item?.hs_tariff_number}
																				</Typography>
																			</Grid>
																			<Grid
																				size={{
																					xs: 12,
																					sm: 4,
																					md: 2,
																					lg: 2,
																					xl: 2
																				}}
																			>
																				<Typography fontSize={11}>
																					Origin Country:{' '}
																					{item?.origin_country}
																				</Typography>
																			</Grid>
																			<Grid
																				size={{
																					xs: 12,
																					sm: 4,
																					md: 2,
																					lg: 2,
																					xl: 2
																				}}
																			>
																				<Typography fontSize={11}>
																					Quantity: {item?.quantity}
																				</Typography>
																			</Grid>
																			<Grid
																				size={{
																					xs: 12,
																					sm: 8,
																					md: 2,
																					lg: 2,
																					xl: 2
																				}}
																				sx={{
																					justifyItems: 'flex-end'
																				}}
																			>
																				<Typography fontSize={11}>
																					Total Value:{' '}
																					{`${currencyToSymbolConversion(
																						item?.currency_code
																					)} ${formatAmount(item?.value)}`}
																				</Typography>
																			</Grid>
																		</Grid>
																	)
																)}
															</AccordionDetails>
														</Accordion>
														<Grid
															container
															size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
															sx={{
																justifyContent: 'flex-end',
																p: 2,
																backgroundColor: theme.palette.grey[100]
															}}
														>
															<Grid>
																<Button
																	variant="contained"
																	size="small"
																	onClick={() =>
																		handleCustomsCommercialDownload(shipment)
																	}
																>
																	Label
																</Button>
															</Grid>
														</Grid>
													</Grid>
												) : (
													<SkeletonCustomItems />
												)}
											</Grid>
										</CardContent>
									</Card>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				) : (
					<SkeletonContainer />
				)}
			</Grid>
			<Grid size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
				{shipment ? (
					<TotalsCard
						shipment={shipment}
						formatAmount={formatAmount}
						shipmentProtection={shipmentProtection}
						handleCancelShipment={handleCancelShipment}
						cancelShipmentDisabled={cancelShipmentDisabled}
						loading={loading}
						setLoading={setLoading}
						cancelShipmentFailure={cancelShipmentFailure}
					/>
				) : (
					<SkeletonCard />
				)}
			</Grid>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
					Next steps
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={theme => ({
						position: 'absolute',
						right: 8,
						top: 8,
						color: theme.palette.grey[500]
					})}
				>
					<CloseIcon />
				</IconButton>

				<DialogContent sx={{ pt: 0 }}>
					<ol>
						<li>Click Download Label and Download Invoice below.</li>
						<li>
							If your shipment includes multiple parcels, you will need to download a label for each
							parcel. When you click “Download Labels,” the labels will open in new tabs.
						</li>
						<li>Please ensure your browser is set to allow multiple tabs/pop-ups.</li>
						<li>Print the label(s) and the invoice, and attach both to your parcel(s).</li>
						<li>UPS shipments: Attach two physical copies of the invoice to the parcel.</li>
						<li>Add the tracking information to your webstore.</li>
						<li>
							If you have scheduled a pick-up, please ensure your parcel is ready for collection at the
							selected time slot.
						</li>
						<li>
							If you have selected "I am sending my parcel to TUNL for pick up" - please send the parcel
							to our warehouse{' '}
							<a
								href="https://help.tunl.to/en/article/office-address-1h1cuh1/"
								target="_blank"
								rel="noreferrer"
								style={{ color: '#50B4BD' }}
							>
								(TUNL, UNIT 52, 22 Cumberland Road, Paarden Eiland, Cape Town, 7405)
							</a>
							.
						</li>
					</ol>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						size="small"
						fullWidth
						onClick={() => {
							downloadAllLabels();
						}}
					>
						<Download />
						Labels
					</Button>
					<Button
						variant="contained"
						size="small"
						fullWidth
						onClick={e => {
							e.stopPropagation();
							handleDownload();
						}}
					>
						<Download />
						Commercial Invoice
					</Button>
				</DialogActions>
			</Dialog>
		</Grid>
	);
}
