import { Grid, Typography } from '@mui/material';
import { Shipment } from '../../../../../../src/types/shipment';
import TEXT from '../../../../../constants/text';
import { shipmentCostTotal } from '../../../../../util/shipments';
import CostItem from '../../../../Common/CostItem';

export interface ShipmentCostDetailsProps {
	shipment: Shipment;
}

export default function ShipmentCostDetails(props: ShipmentCostDetailsProps) {
	const { shipment } = props;

	const shippingCost = parseFloat(shipment.rate_charged) || 0.0;
	const shippingProtection = parseFloat(shipment.insurance?.toString() || '0.0');
	const ddpCost = shipment?.rates_breakdown?.ddp?.landed_costs_total_rands ?? 0;
	const signatureRequiredCost = shipment.delivery_confirmation === 'ADULT_SIGNATURE' ? 50.0 : 0;

	const totalCost = shipmentCostTotal(shipment);

	return (
		<Grid container direction="column">
			<CostItem costPrice={shippingCost} costLabel={TEXT.SHIPPING_COST} />
			<CostItem costPrice={shippingProtection} costLabel={TEXT.SHIPPING_PROTECTION} />
			<CostItem costPrice={ddpCost} costLabel={TEXT.DFT} />
			<CostItem costPrice={signatureRequiredCost} costLabel={TEXT.SIGNATURE_REQUIRED} />

			<Grid item>
				<Typography variant="body1" sx={{ fontWeight: 'bold' }}>
					Total: {'R'}
					{totalCost}
				</Typography>
			</Grid>
		</Grid>
	);
}
