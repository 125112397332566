import { yupResolver } from '@hookform/resolvers/yup';
import InfoIcon from '@mui/icons-material/Info';
import { AccordionActions, Alert, Button, Grid2 as Grid } from '@mui/material';
import { debounce, isEqual } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { ShipmentActionTypes } from '../../context/actions';
import { useShipmentContext } from '../../hooks/useShipmentContext';
import { packagesSchema, parcelBaseSchema } from '../../schema/Parcel';
import { CustomsInfoData, ParcelBase, ParcelData, ParcelsWithCustomsData } from '../../types/consignment';
import ParcelItem from './ParcelItems';

interface Props {}

export const parcels: ParcelData[] = [
	{
		name: 'parcel-1',
		weight: 0,
		height: 0,
		length: 0,
		width: 0,
		customsItems: [
			{
				description: '',
				quantity: 0,
				weight: 0,
				value: 0,
				hsTariffNumber: '',
				originCountry: 'ZA',
				lookUp: {
					hsTariffNumber: '',
					description: '',
					error: ''
				}
			}
		]
	}
];
export const customsInfo: CustomsInfoData = {
	currency: '',
	invoiceNumber: '',
	productReference: '',
	contentsType: 'merchandise'
};

let parcelState = [
	{
		weight: 0,
		height: 0,
		length: 0,
		width: 0
	}
];

let consignmentState = {
	parcels: parcels,
	customsInfo: customsInfo
};

export const isParcelValid = (partialParcels: ParcelBase[]) => {
	const validation = new Set(
		partialParcels.map(parcel => {
			return parcelBaseSchema.isValidSync(parcel);
		})
	);

	const firstValue = validation.values().next().value;
	if (validation.size === 1 && firstValue === true) {
		return true;
	}
	return false;
};

export default function ParcelContainer({}: Props) {
	const shipmentContext = useShipmentContext();
	const { state, dispatch } = shipmentContext;
	let isComplete = false;
	const {
		control,
		register,
		getValues,
		reset,
		setValue,
		watch,
		trigger,
		formState: { errors }
	} = useForm({
		defaultValues: { parcels: parcels, customsInfo: customsInfo },
		resolver: yupResolver(packagesSchema)
	});

	const isConsignmentValid = (parcelData: ParcelsWithCustomsData) => {
		return packagesSchema.isValidSync(parcelData);
	};

	const hasParcelsChanged = (partialParcels: ParcelBase[]) => {
		return !isEqual(partialParcels, parcelState);
	};

	const hasConsignmentChanged = (parcelData: ParcelsWithCustomsData) => {
		return !isEqual(parcelData, consignmentState);
	};

	const dispatchParcelEvent = (parcelData: ParcelsWithCustomsData) => {
		const consignmentData = parcelData;
		const customsInfo = consignmentData.customsInfo;

		for (let p = 0; p < consignmentData.parcels.length; p++) {
			for (let ci = 0; ci < consignmentData.parcels[p].customsItems.length; ci++) {
				consignmentData.parcels[p].customsItems[ci].currency = customsInfo.currency;
			}
		}
		dispatch({
			type: ShipmentActionTypes.UPDATE_PARCELS,
			payload: consignmentData
		});
		isComplete = true;
		dispatch({
			type: ShipmentActionTypes.UPDATE_FORM_SECTION_STATE,
			payload: {
				sectionName: 'parcels',
				complete: true
			}
		});
	};

	const handleParcelState = useCallback((event: any) => {
		const parcelData: ParcelsWithCustomsData = {
			parcels: getValues('parcels') as ParcelData[],
			customsInfo: getValues('customsInfo') as CustomsInfoData
		};

		const partialParcels: ParcelBase[] = parcelData.parcels.map(parcel => {
			return {
				weight: parcel.weight,
				height: parcel.height,
				length: parcel.length,
				width: parcel.width
			};
		});

		if (isConsignmentValid(parcelData)) {
			if (hasConsignmentChanged(parcelData)) {
				consignmentState = structuredClone(parcelData);
				dispatchParcelEvent(parcelData);
			}
		} else {
			if (isComplete === true) {
				isComplete = false;
				dispatch({
					type: ShipmentActionTypes.UPDATE_FORM_SECTION_STATE,
					payload: {
						sectionName: 'parcels',
						complete: false
					}
				});
			}
		}
		if (isParcelValid(partialParcels) && hasParcelsChanged(partialParcels)) {
			dispatch({
				type: ShipmentActionTypes.UPDATE_PARCELS,
				payload: parcelData
			});
			dispatch({
				type: ShipmentActionTypes.UPDATE_TRIGGER_STATE,
				payload: {
					key: 'validParcelDimensions',
					value: true
				}
			});
			parcelState = partialParcels;
		}
		return event;
	}, []);

	const debouncedOnParcelStateChange = useMemo(
		() =>
			debounce(event => {
				handleParcelState(event);
			}, 2000),
		[handleParcelState]
	);

	useEffect(() => {
		return () => {
			debouncedOnParcelStateChange.cancel();
		};
	}, [debouncedOnParcelStateChange]);

	const event = watch('parcels');
	debouncedOnParcelStateChange(event);

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'parcels',
		rules: { minLength: 1 }
	});

	return (
		<Grid>
			<Alert icon={<InfoIcon fontSize="small" />} variant="outlined" severity="info">
				If your parcel is bulky, couriers will charge based on volumetric weight, calculated from the size of
				your parcel, rather than actual weight/mass.
			</Alert>
			<ParcelItem
				control={control}
				register={register}
				getValues={getValues}
				setValue={setValue}
				errors={errors}
				fields={fields}
				watch={watch}
				remove={remove}
				trigger={trigger}
			/>

			<AccordionActions>
				<Button
					variant="contained"
					onClick={() => {
						append({
							name: `parcel-${fields.length + 1}`,
							weight: 0,
							height: 0,
							length: 0,
							width: 0,
							customsItems: [
								{
									description: '',
									quantity: 0,
									weight: 0,
									value: 0,
									hsTariffNumber: '',
									originCountry: 'ZA',
									lookUp: {
										hsTariffNumber: '',
										description: '',
										error: ''
									}
								}
							]
						});
					}}
				>
					Add parcel
				</Button>
			</AccordionActions>
		</Grid>
	);
}
